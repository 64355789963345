import { Button, Flex, Popover, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import CheckpointItem from './CheckpointItem'
import useTrainLora from '../../hooks/useTrainLora'
import { CloseOutlined } from '@ant-design/icons'

type Props = {
  checkPoints: any
  checkPointSelected: any
  handleSelectCheckpoint: any
}
export default function CheckpointList(props: Props) {
  const { checkPoints, checkPointSelected, handleSelectCheckpoint } = props
  const [openCheckPointModal, setOpenCheckPointModal] = useState(false)
  const [activeCheckpoint, setActiveCheckpoint] = useState(null)
  const onSelect = (checkpoint: any) => {
    if (!checkpoint) return
    setOpenCheckPointModal(false)
    handleSelectCheckpoint(checkpoint)
  }
  useEffect(() => {
    if (checkPoints && checkPoints.length > 0) {
      setActiveCheckpoint(checkPoints[0])
    }
  }, [checkPoints])
  useEffect(() => {
    if (checkPointSelected && checkPointSelected.name) {
      setActiveCheckpoint(checkPointSelected)
    }
  }, [checkPointSelected])
  return (
    <>
      <Space direction='vertical'>
        {!checkPointSelected.name &&
          checkPoints.map((x: any) => (
            <CheckpointItem
              key={x.checkpoint}
              activeCheckpoint={activeCheckpoint || checkPointSelected}
              onSelect={(checkpoint) => onSelect(checkpoint)}
              data={x}
            />
          ))}

        {checkPointSelected.name && (
          <CheckpointItem
            onSelect={(checkpoint) => onSelect(checkpoint)}
            data={checkPointSelected}
            onOpenReselect={() => setOpenCheckPointModal(true)}
          />
        )}
      </Space>

      <div
        className='bg-white fixed left-[325px] w-[354px] h-[500px] top-[125px] z-[100] rounded-xl'
        style={{
          display: openCheckPointModal ? '' : 'none'
        }}
      >
        <div className='h-[440px] overflow-x-hidden'>
          <div className='flex mb-4 justify-between pl-5 pt-5 pr-5'>
            <label className='text-sm font-bold'>ベースモデルを選択</label>
            <CloseOutlined className='text-lg cursor-pointer' onClick={() => setOpenCheckPointModal(false)} />
          </div>
          <div className='bg-[#EEEEF4] w-full h-[2px] z-[20] left-0 top-[45px]'></div>
          <div className='h-[85%] overflow-y-auto overflow-x-hidden p-5 relative'>
            {checkPoints.map((x: any) => (
              <CheckpointItem
                key={x.name}
                data={x}
                activeCheckpoint={activeCheckpoint || checkPointSelected}
                onSelect={() => setActiveCheckpoint(x)}
                style={{ width: 314 }}
              />
            ))}
          </div>
        </div>
        <Flex align='center' justify='center'>
          <Button
            className='w-[256px] h-[40px] bg-[#323539] rounded-md text-white text-sm font-medium mt-2'
            onClick={() => onSelect(activeCheckpoint)}
          >
            &#8203;確定
          </Button>
        </Flex>
      </div>
    </>
  )
}
