import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import CommonAPIs from '../../controller/API/CommonAPIs'
import { useLocation } from 'react-router-dom'

const PermissionPopup = () => {
	const [openPermissionWaring, setOpenPermissionWarning] = useState(false)
	const location = useLocation();

	useEffect(() => {
    CommonAPIs.getUserInfo()
      .then((res) => {
        if (!res.is_permission_txt2img && window.location.pathname === '/text2img') {
          setOpenPermissionWarning(true)
        } else if (!res.is_permission_img2img && window.location.pathname === '/img2img') {
          setOpenPermissionWarning(true)
        } else if (!res.is_permission_change_fabric && window.location.pathname === '/change-material') {
          setOpenPermissionWarning(true)
        } else if (!res.is_permission_product_content && window.location.pathname === '/create-product-content') {
          setOpenPermissionWarning(true)
        } else if (!res.is_permission_train_lora && window.location.pathname === '/train-lora') {
          setOpenPermissionWarning(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [location.pathname])

	return (
		<Modal
			className='px-0'
			centered
			open={openPermissionWaring}
			maskClosable={false}
			onCancel={() => {
				setOpenPermissionWarning(false)
				window.location.href = '/'
			}}
			width={600}
			footer=''
		>
			<div className='w-full grid mt-10'>
				<img
					className='w-[53px] justify-self-center'
					src={require('../../assets/permissions_warning.png')}
					alt='permission warning'
				/>
			</div>
			<p className='text-center text-[16px]'>
				この機能は現在ご利用いただけません。
				<br />
				ご利用希望の場合は、サポートにご連絡ください。
			</p>
			<hr className=' my-9' />
			<div className='w-full grid'>
				<a
					href='https://tayori.com/form/c7a8d8d0678244b20aa023a5fcb13577f37688ee/'
					target='_blank'
					rel='noopener noreferrer'
					className='bg-[#323539] flex items-center justify-center text-[16px] w-[198px] h-[50px] rounded-xl justify-self-center text-white line-[50px] hover:text-white'
				>
					サポートに連絡する
				</a>
				<a
					onClick={() => {
						setOpenPermissionWarning(false)
						window.location.href = '/'
					}}
					href='/'
					target='_blank'
					rel='noopener noreferrer'
					className='justify-self-center mt-6 text-[16px] underline decoration-1 text-[#323539]'
				>
					ホーム画面に戻る
				</a>
			</div>
		</Modal>
	)
}

export default PermissionPopup
