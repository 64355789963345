import React, { useEffect, useRef } from 'react'
import { convertMaskToCanvas } from '../utils/ImageHandler'
export default function CanvasMask({ maskImage }: any) {
  const canvasRef = useRef(null)
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas && maskImage) {
        convertMaskToCanvas(maskImage, canvas)
        .then(() => {
          console.log('Image processing complete')
        })
        .catch((error) => {
          console.error('Image processing error:', error)
        })
    }
  }, [maskImage])
  return <canvas className='absolute' style={{ width: 500 }} ref={canvasRef}></canvas>
}
