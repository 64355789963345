import React, { useEffect, useState } from 'react'
import CommonAPIs from '../../controller/API/CommonAPIs'
import countdownIcon from '../../assets/icons/countdown-icon.svg'
import { formatNumber } from '../../utils/utils'
export default function HeaderCountdown(props: any) {
  const { isShowTime } = props
  const [loading, setLoading] = useState(false)
  const [remainingTime, setRemaining] = useState(0)
  const email = localStorage.getItem('email') || ''

  const init = async () => {
    try {
      setLoading(true)
      const res = await CommonAPIs.getMySecondsTime()
      const allTime = Number(res?.all_time / 60).toFixed(0)
      const usedTime = Number(res?.time_used / 60).toFixed(0)
      setRemaining(Number(allTime) - Number(usedTime))
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    init()
  }, [])
  return !loading ? (
    <div className=' flex justify-center items-center'>
      {isShowTime && (
        <>
          <img className='mt-1 mr-2' src={countdownIcon} />
          <div className='text-xs font-normal mr-2'> 残り分: {formatNumber(remainingTime)}</div>
        </>
      )}
      <div
        className='w-11 h-11 rounded-full flex justify-center items-center uppercase text-2xl font-bold text-[#323539]'
        style={{ border: 'solid 3px #F0F0F0' }}
      >
        {email.charAt(0)}
      </div>
    </div>
  ) : (
    <></>
  )
}
