import { Flex, Button, Typography, Row, Col, Select, Input } from 'antd'
import { useContext, useEffect, useState } from 'react'
import UploadImageDragger from './components/UploadImageDragger'
import { CheckpointTypes } from '../../types'
import useTrainLora from '../../hooks/useTrainLora'
import { AppContext } from '../../hooks/AppContext'
import Constant from '../../controller/Constant'

const TrainLoraScreen = () => {
  const { isMobile, setMenuSelected } = useContext(AppContext)

  const {
    loraProcessingJobID,
    getLoraProcessing,
    isStopLoop,
    checkIsDisabled,
    checkPointSelected,
    handleSelectCheckpoint,
    checkPoints,
    setLoraName,
    handleRequestTrainLora,
    handleUploadChange,
    beforeUpload,
    removeImage,
    handleSelfieWebcam,
    fileListBase64,
    getCheckpoint,
    loraStatus,
    isVisibleOptionModal,
    setIsVisibleOptionModal,
    generatedLora,
    validationErrors,
    onNavigation,
    checkpointProfileEdit,
    setCheckpointProfileEdit,
    updateCheckpointProfile,
    checkpointProfileValidate
  } = useTrainLora()

  useEffect(() => {
    getCheckpoint()
    setMenuSelected(Constant.keyScreen.trainLora)
  }, [])

  useEffect(() => {
    if (loraProcessingJobID) {
      const timerId = setInterval(() => {
        getLoraProcessing(loraProcessingJobID)
      }, 5000)

      if (isStopLoop) {
        clearInterval(timerId)
      }

      return () => {
        clearInterval(timerId)
      }
    }
  }, [loraProcessingJobID, isStopLoop])

  return (
    <>
      <Typography className='title '>Loraモデル学習</Typography>
      <Flex vertical justify='center' align='center'>
        <Flex vertical justify='center' align='center'>
          <UploadImageDragger
            isDisabled={checkIsDisabled()}
            handleUploadChange={handleUploadChange}
            beforeUpload={beforeUpload}
            removeImage={removeImage}
            fileListBase64={fileListBase64}
            isVisibleOptionModal={isVisibleOptionModal}
            setIsVisibleOptionModal={setIsVisibleOptionModal}
          />
          <Col
            style={{
              marginLeft: isMobile ? 0 : 15,
              alignSelf: isMobile ? 'flex-start' : 'center'
            }}
          >
            <Row className='items-center justify-center mb-[10px]'>
              <Typography style={{ marginRight: isMobile ? 30 : 60 }}>モデル</Typography>
              <Select
                className='h-[36px]'
                style={{ width: isMobile ? 180 : 270 }}
                disabled={checkIsDisabled()}
                value={checkPointSelected?.checkpoint}
                onChange={handleSelectCheckpoint}
              >
                {checkPoints?.map((item: CheckpointTypes) => (
                  <Select.Option key={item?.name} value={item.name} checkpoint={item.checkpoint}>
                    <p>{item?.checkpoint}</p>
                  </Select.Option>
                ))}
              </Select>
            </Row>
            <Row className='items-start justify-center mb-[10px]'>
              <Typography style={{ marginRight: isMobile ? 30 : 60 }} className='text-start pt-1'>
                Lora名
              </Typography>
              <Flex vertical>
                <Input
                  className='h-[36px]'
                  placeholder='Lora名を英数字で入力してください'
                  style={{ width: isMobile ? 180 : 270 }}
                  allowClear
                  onChange={(e: any) => setLoraName(e.target?.value)}
                  disabled={checkIsDisabled()}
                />
                <Typography className='text-[12px] ml-3 text-red-600 mt-2'>
                  {validationErrors?.name?.message}
                </Typography>
              </Flex>
            </Row>
          </Col>
          <Button
            className='w-[105px] mt-[30px] mb-[30px] font-bold'
            onClick={handleRequestTrainLora}
            type='primary'
            disabled={checkIsDisabled()}
          >
            学習する
          </Button>
          <Flex justify='center' className='mb-[80px] mt-[30px] w-full'>
            {[
              Constant.mediaProcessing.PROCESSING,
              Constant.mediaProcessing.SUCCESS,
              Constant.mediaProcessing.FAIL
            ].includes(loraStatus) && (
              <Flex vertical justify='center' className='min-w-[560px] '>
                <Typography.Text className='font-bold text-[18px] justify-start '>結果</Typography.Text>
                {loraStatus == Constant.mediaProcessing.PROCESSING && (
                  <Flex vertical justify='center' align='center'>
                    <img
                      className='flex justify-center items-center mt-[15px] mb-5'
                      src={require('../../assets/processing.gif')}
                      width={'auto'}
                      height={80}
                    />
                  </Flex>
                )}
                {loraStatus == Constant.mediaProcessing.SUCCESS && (
                  <Flex vertical justify='center' align='center'>
                    <img
                      className='flex justify-center items-center mt-[15px] mb-5'
                      src={require('../../assets/file-download-success.png')}
                      width={60}
                      height={60}
                    />
                    <Typography.Text className='whitespace-pre-line self-start text-[16px] font-bold '>
                      {`Loraモデルの学習が完了しました。ダウンロードして確認お願いします。\nそしてチェックポイントマージも終わりました。画像を生成してみましょう！`}
                    </Typography.Text>
                    <Button
                      href={generatedLora?.url}
                      className='font-bold w-[210px] h-[31px] bg-[#03B048] hover:bg-[#03B048] text-white mt-5'
                    >
                      Loraファイルをダウンロード
                    </Button>
                    {/* <div
                      onClick={onNavigation}
                      className='border-b-[1px] border-[#336CDB] text-[#336CDB] font-bold mt-5 cursor-pointer'
                      style={{
                        borderBottomStyle: 'solid'
                      }}
                    >
                      画像生成画面へ
                    </div> */}
                    <Flex vertical className='mt-[33px]'>
                      <Typography className='text-[16px] font-bold'>ブランドプロフィールの設定</Typography>
                      <Typography className='whitespace-pre-line flex-wrap max-w-[560px] mb-4 text-[12px] mt-2 font-bold '>
                        {`ブランドの特徴を教えてください。\nここでは「ブランドプロフィール」とし、あなたのブランドのスタイルやコンセプト、顧客層など、ブランドの独自性を反映したキーワードを入力してください`}
                      </Typography>
                      <Input.TextArea
                        value={checkpointProfileEdit}
                        onChange={(e) => setCheckpointProfileEdit(e?.target?.value)}
                        style={{
                          overflow: 'scroll',
                          scrollbarWidth: 'none'
                        }}
                        placeholder='プロフィールの設定をしてください'
                        autoSize={{ minRows: 2, maxRows: 2 }}
                      />
                      {checkpointProfileValidate && (
                        <Typography className='text-red-500 text-[12px] '>※プロフィールを設定してください</Typography>
                      )}
                    </Flex>
                    <Button onClick={updateCheckpointProfile} type='primary' className='pl-10 pr-10 font-bold mt-4'>
                      保存
                    </Button>
                  </Flex>
                )}
                {loraStatus == Constant.mediaProcessing.FAIL && (
                  <Flex vertical justify='center' align='center'>
                    <img
                      className='flex justify-center items-center mt-[15px mb-5]'
                      src={require('../../assets/file-download-fail.png')}
                      width={60}
                      height={60}
                    />
                    <Typography.Text className='text-red-600'>
                      Loraモデルの学習が失敗しました。再度学習してください。
                    </Typography.Text>
                  </Flex>
                )}
              </Flex>
            )}
            <Typography.Text className='w-6'></Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default TrainLoraScreen
