import { useContext, useState } from 'react'
import { Input, Flex, Button } from 'antd'
import '../../controller/styles.css'
import logo from '../../assets/logo_big.png'
import { useNavigate } from 'react-router-dom'
import CommonAPIs from '../../controller/API/CommonAPIs'
import { AppContext } from '../../hooks/AppContext'

const RegisterScreen = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [companyWebsite, setCompanyWebsite] = useState<string>('')
  const [policyAcceptance, setPolicyAcceptance] = useState(false)
  const [validationErrors, setValidationErrors] = useState({
    email: '', 
    phone: '',
    name: '', 
    password: '',
    passwordConfirmation: '', 
    company: '', 
    companyWebsite: '',
    policy: ''
  })
  const { setIsSpinning, showErrorApiAlert, showProcessingAlert } = useContext(AppContext)

  const handleRegistration = () => {
    let formInvalid = false
    setValidationErrors({
      email: '', 
      phone: '',
      name: '', 
      password: '',
      passwordConfirmation: '', 
      company: '', 
      companyWebsite: '',
      policy: ''
    })
    
    // policy
    if (!policyAcceptance) {
      setValidationErrors((prev) => {
        return {...prev, 'policy': '利用規約とプライバシーポリシーに同意してください。'}
      })
      formInvalid = true
    }

    // email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setValidationErrors((prev) => {
        return {...prev, 'email': 'メールアドレスを入力してください。'}
      })
      formInvalid = true
    }else if (!emailRegex.test(email)) {
      setValidationErrors((prev) => {
        return {...prev, 'email': 'メールアドレスの形式が間違っています。'}
      })
      formInvalid = true
    }

    //phone
    const phoneRegex = /^\d{7,11}$/;
    if (!phone) {
      setValidationErrors((prev) => {
        return {...prev, 'phone': '電話番号を入力してください。'}
      })
      formInvalid = true
    } else if (!phoneRegex.test(phone)) {
      setValidationErrors((prev) => {
        return {...prev, 'phone': '電話番号を7行以上11行以下で入力してください。'}
      })
      formInvalid = true
    }

    // name
    if (!name) {
      setValidationErrors((prev) => {
        return {...prev, 'name': 'ユーザー名を入力してください。'}
      })
      formInvalid = true
    }
    
    // password
    if (!password) {
      setValidationErrors((prev) => {
        return {...prev, 'password': 'パスワードを入力してください。'}
      })
      formInvalid = true
    } else if (password.length < 6) {
      setValidationErrors((prev) => {
        return {...prev, 'password': 'パスワードは6文字以上入力してください。'}
      })
      formInvalid = true
    }
    
    // password confirmation
    if (!passwordConfirmation) {
      setValidationErrors((prev) => {
        return {...prev, passwordConfirmation: '確認用パスワードを入力してください。'}
      })
      formInvalid = true
    } else if (passwordConfirmation.length < 6) {
      setValidationErrors((prev) => {
        return {...prev, passwordConfirmation: '確認用パスワードは6文字以上入力してください。'}
      })
      formInvalid = true
    } else if (passwordConfirmation !== password) {
      setValidationErrors((prev) => {
        return {...prev, passwordConfirmation: 'パスワードと確認用パスワードが一致していません。'}
      })
      formInvalid = true
    }
    
    // company name
    if (!company) {
      setValidationErrors((prev) => {
        return {...prev, 'company': '会社名を入力してください。'}
      })
      formInvalid = true
    }
    
    if (formInvalid) {
      return
    }

    setIsSpinning(true)
    CommonAPIs.sendRegistrationApi(email, phone, name, password, passwordConfirmation, company, companyWebsite)
      .then((res) => {
				showProcessingAlert('新規会員登録に成功しました。')
				navigate('/login')
			})
      .catch((err) => {
        if (err.response.data.message == 'このメールアドレスがすでに存在します。') {
          setValidationErrors((prev) => {
            return {...prev, 'email': 'このメールアドレスがすでに存在します。'}
          })
          return
        }
        
        showErrorApiAlert(err)
      })
      .finally(() => setIsSpinning(false))
  } 

  return (
    <Flex vertical justify='center' align='center' className='bg-white'>
      <img className='mt-10 mb-3' width={120} src={logo} alt='' />
      <div className='md:w-[40%] xs:w-[80%] bg-[#F6F6F6] flex justify-center content-center rounded-lg mt-6 mb-10'>
        <Flex vertical className='mt-6 max-w-[363px] w-[80%]'>
          <span className='font-bold text-[24px] text-center'>新規会員登録</span>
          <label className='text-[16px] mt-6 mb-2'>
            メールアドレス <span className='text-[#DC3545]'>*</span>
          </label>
          <Input
            placeholder='メールアドレスを入力'
            onChange={(text: any) => setEmail(text?.target?.value)}
            className={`w-full ${validationErrors.email ? 'border-rose-600' : ''}`}
          />
          <span className='text-[#DC3545] text-[14px] mt-1'>{ validationErrors.email }</span>
          
          <label className='text-[16px] mt-6 mb-2'>
            電話番号 <span className='text-[#DC3545]'>*</span>
          </label>
          <Input
            placeholder='電話番号を入力'
            onChange={(text: any) => setPhone(text?.target?.value)}
            className={`w-full ${validationErrors.phone ? 'border-rose-600' : ''}`}
          />
          <span className='text-[#DC3545] text-[14px] mt-1'>{ validationErrors.phone }</span>
          
          <label className='text-[16px] mb-2 mt-5'>
            ユーザー名 <span className='text-[#DC3545]'>*</span>
          </label>
          <Input
            placeholder='ユーザー名を入力'
            onChange={(text: any) => setName(text?.target?.value)}
            className={`w-full ${validationErrors.name ? 'border-rose-600' : ''}`}
          />
          <span className='text-[#DC3545] text-[14px] mt-1'>{ validationErrors.name }</span>
          
          <label className='text-[16px] mb-2 mt-5'>
            パスワード <span className='text-[#DC3545]'>*</span>
          </label>
          <Input.Password
            type='password'
            onChange={(text: any) => setPassword(text?.target?.value)}
            className={`w-full ${validationErrors.password ? 'border-rose-600' : ''}`}
          />
          <span className='text-[#DC3545] text-[14px] mt-1'>{ validationErrors.password }</span>
          
          <label className='text-[16px] mb-2 mt-5'>
            確認用パスワード <span className='text-[#DC3545]'>*</span>
          </label>
          <Input.Password
            type='password'
            onChange={(text: any) => setPasswordConfirmation(text?.target?.value)}
            className={`w-full ${validationErrors.passwordConfirmation ? 'border-rose-600' : ''}`}
          />
          <span className='text-[#DC3545] text-[14px] mt-1'>{ validationErrors.passwordConfirmation }</span>
          
          <label className='text-[16px] mb-2 mt-5'>
            会社名 <span className='text-[#DC3545]'>*</span>
          </label>
          <Input
            placeholder='会社名を入力'
            onChange={(text: any) => setCompany(text?.target?.value)}
            className={`w-full ${validationErrors.company ? 'border-rose-600' : ''}`}
          />
          <span className='text-[#DC3545] text-[14px] mt-1'>{ validationErrors.company }</span>
          
          <label className='text-[16px] mb-2 mt-5'>会社のウェブサイト</label>
          <Input
            placeholder='https://creativelink.snafty.ai/'
            onChange={(text: any) => setCompanyWebsite(text?.target?.value)}
            className='w-full'
          />
          <span className='text-[12px] mt-8'>利用規約・プライバシーポリシーをご確認の上、以下にチェックを入れてください。</span>
          <Flex className='mt-3'>
            <input type='checkbox' checked={policyAcceptance} onChange={() => setPolicyAcceptance(!policyAcceptance)} />
            <span className='text-[12px] ml-2'>
              <a className='underline text-[#212529] hover:underline visited:underline focus:underline' target='_blank' rel='noreferrer' href='https://nft-snap-dev.s3.ap-northeast-1.amazonaws.com/jewelry_pdf/%E3%82%AF%E3%83%AA%E3%83%AA%E3%83%B3%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84_20240626%E5%AE%8C%E6%88%90%E7%89%88.pdf'>
                利用規約
              </a>
              ・
              <a className='underline text-[#212529] hover:underline visited:underline focus:underline' target='_blank' rel='noreferrer' href='https://nft-snap-dev.s3.ap-northeast-1.amazonaws.com/jewelry_pdf/%E3%82%AF%E3%83%AA%E3%83%AA%E3%83%B3_%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC_2024626%E5%AE%8C%E6%88%90%E7%89%88.pdf'>
                プライバシーポリシー
              </a>
              に同意する
            </span>
          </Flex>
          <span className='text-[#DC3545] text-[12px] mt-1'>{ validationErrors.policy }</span>
          <Button onClick={() => handleRegistration()} className='bg-[#323539] text-white mt-10 text-[16px]'>
            新規登録する
          </Button>
          <span className='text-[12px] my-10 text-center'>すでにアカウントをお持ちの方は<a href='/login' className='underline hover:underline visited:underline focus:underline text-[#212529]'>ログイン</a></span>
        </Flex>
      </div>
    </Flex>
  )
}

export default RegisterScreen
