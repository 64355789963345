import axios from 'axios'
import BaseAPIs from './BaseAPI'

export default class Img2ImgAPIs extends BaseAPIs {
  static endpoints = {
    threadImg2ImgHistory: this.baseURL + '/ai-img/threads',
    threadList: this.baseURL + '/ai-img/threads/by-function?function_type=3',
    deleteThread: this.baseURL + '/ai-img/threads',
    editThreadName: this.baseURL + '/ai-img/threads'
  }

  static async getThreadImg2ImgHistory(threadID: number) {
    const token = localStorage.getItem('token')

    try {
      const url = `${Img2ImgAPIs.endpoints.threadImg2ImgHistory}/${threadID}/content-by-thread`
      let response = await axios.get(url, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getThreadList() {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.get(Img2ImgAPIs.endpoints.threadList, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async deleteThread(threadID: number) {
    const token = localStorage.getItem('token')

    const url = `${Img2ImgAPIs.endpoints.deleteThread}/${threadID}`

    try {
      let response = await axios.delete(url, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async editThreadName(threadID: number, editText: string) {
    const token = localStorage.getItem('token')
    console.log('threadID:', threadID)
    console.log('editText:', editText)

    const formData = new FormData()
    formData.append('name', editText)

    const url = `${Img2ImgAPIs.endpoints.editThreadName}/${threadID}?_method=PUT`

    try {
      let response = await axios.post(url, formData, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
