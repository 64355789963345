import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react'
import { Button, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'

const BeforeUnloadModal = forwardRef((props, ref) => {
  const [openModal, setOpenModal] = useState(false)
  const [nextUrl, setNextUrl] = useState('')
  const navigate = useNavigate()
  const navigateWithBeforeUnload = (url: string) => {
    if ((window as any).isBeforeUnloadPrevent) {
      setOpenModal(true)
      setNextUrl(url)
    } else {
      navigate(url)
    }
  }

  useImperativeHandle(ref, () => ({
    navigateWithBeforeUnload
  }))

  return (
    <Modal
      title={<div className='text-[black] text-xl'>このページを離れますか？</div>}
      open={openModal}
      centered
      width={750}
      onCancel={() => setOpenModal(false)}
      footer={[
        <div className='flex justify-end mt-10' key={'ok'}>
          <Button className='w-[180px] h-[44px] mr-3 cursor-pointer' onClick={() => setOpenModal(false)}>
            いいえ
          </Button>
          <Button
            className='w-[180px] h-[44px] bg-[#323539] text-white cursor-pointer'
            onClick={() => navigate(nextUrl)}
          >
            はい
          </Button>
        </div>
      ]}
    >
      <div className='text-[14px] text-[#54595E]'>行った変更が保存されない可能性があります。</div>
    </Modal>
  )
})

export default BeforeUnloadModal
