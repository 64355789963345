import React from 'react'

const ImageGrid = ({ images }) => {
  return (
    <div className='image-grid'>
      {images.map((image, index) => (
        <div key={index} className='image-item'>
          <img src={image} alt={`Image ${index + 1}`} />
        </div>
      ))}
    </div>
  )
}

export default ImageGrid
