import { Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import Constant from '../../../controller/Constant'
import { useThreadStore } from '../../../controller/store'

type Props = {}

const SelectCheckPointGuideView = (props: Props) => {
  const [guideText, setGuideText] = useState<string>('')
  const { countNewThread } = useThreadStore()

  useEffect(() => {
    const textArray = Constant.selectCheckpointGuideText
    let i = 0
    const interval = setInterval(() => {
      if (i < textArray.length) {
        setGuideText(textArray[i])
        i++
      } else {
        clearInterval(interval)
      }
    }, 100)
  }, [countNewThread])

  return (
    <Row className='items-center my-7 '>
      <img src={require('../../../assets/ic_bot.png')} style={{ height: 44, aspectRatio: 1 }} />
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 'bold',
          marginLeft: 22
        }}
      >
        {guideText}
      </Typography>
    </Row>
  )
}

export default SelectCheckPointGuideView
