import { Layout } from 'antd'
import useMaterial from '../../hooks/useMaterial'
import UploadImg2Img from './components/UploadImg2Img'
import FabricCoordination from './components/FabricCoordination'
import ParameterAdjustment from './components/ParameterAdjustment'
import ImgResultView from './components/ImgResultView'
import ImageBlend from '../ImageBlend'
import { useState, useEffect, useContext } from 'react'
import { AppContext } from '../../hooks/AppContext'
import Constant from '../../controller/Constant'
import Introduction from './components/Introduction'
import { useThreadStore } from '../../controller/store'
import { addBeforeUnload } from '../../utils/utils'

const ChangeMaterialScreen = () => {
  const { setMenuSelected } = useContext(AppContext)
  const { handleUploadChange, beforeUpload, fileBase64, setFileBase64 } = useMaterial()
  const [step, setStep] = useState(1)
  const [transparentImage, setTransparentImage] = useState('')
  const [blendedImage, setBlendedImage] = useState('')
  const [imageResults, setImageResults] = useState<string[]>([])
  const [fabricImg, setFabricImg] = useState('')
  const [category, setCategory] = useState('')
  const [maskImg, setMaskImg] = useState('')
  const [batchSize, setBatchSize] = useState('')
  const [isShowIntroduction, setIsShowIntroduction] = useState<boolean>(false)
  const { setScreenKey } = useThreadStore()
  console.log('imageResults:', imageResults)

  const checkShowIntroduction = () => {
    const isHideLoraIntro = localStorage.getItem(Constant.localStorageKeys.isHideChangeFabricIntro)
    setIsShowIntroduction(isHideLoraIntro === 'true' ? false : true)
    localStorage.setItem(Constant.localStorageKeys.isHideChangeFabricIntro, 'true')
  }

  useEffect(() => {
    setMenuSelected(Constant.keyScreen.changeMaterial)
    checkShowIntroduction()
    setScreenKey(Constant.keyScreen.txt2img)
  }, [])

  const getLayoutStyle = () => {
    // if (step == 1) {
    return 'bg-[#F6F6F6] [&>div]:p-0 h-full'
    // }
    // return 'bg-white [&>div]:p-0 h-full'
  }

  useEffect(() => {
    setScreenKey(Constant.keyScreen.changeMaterial)
  }, [])

  useEffect(() => {
    if (fileBase64.url) {
      addBeforeUnload()
    }
  }, [fileBase64])
  return (
    <Layout className={getLayoutStyle()}>
      {step === 1 &&
        (fileBase64.url ? (
          <FabricCoordination
            backgroundImage={fileBase64}
            setClothesImg={setFileBase64}
            onNextStep={(result, fabricImg, category, maskImage, batchSize) => {
              setStep(2)
              setTransparentImage(result)
              setFabricImg(fabricImg)
              setCategory(category)
              setMaskImg(maskImage)
              setBatchSize(batchSize)
            }}
          />
        ) : (
          <UploadImg2Img handleUploadChange={handleUploadChange} beforeUpload={beforeUpload} fileBase64={fileBase64} />
        ))}
      {step === 2 && (
        <ImageBlend
          fabricImg={fabricImg}
          transparentImage={transparentImage}
          onBack={() => setStep(1)}
          onNextStep={(result) => {
            setStep(3)
            setBlendedImage(result)
          }}
        />
      )}
      {step === 3 && (
        <ParameterAdjustment
          blendedImage={blendedImage}
          originalImage={fileBase64.url}
          fabric={fabricImg}
          category={category}
          maskImg={maskImg}
          batchSize={batchSize}
          onBack={() => setStep(2)}
          onNextStep={(result: any) => {
            setStep(4)
            setImageResults(result)
          }}
        />
      )}
      {step === 4 && <ImgResultView imageResults={imageResults} originalImage={fileBase64.url} />}
      {isShowIntroduction && <Introduction />}
    </Layout>
  )
}

export default ChangeMaterialScreen
