import * as Yup from 'yup'
import * as _ from 'lodash'
import { RequestGenerateLoraType } from '../types/media_type'
import { message } from 'antd'

const createLoraValidation = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'ブランド名を英数字で入力してください。')
    .max(255, 'ブランド名を255文字以下で入力してください。')
    .nullable()
    .required('ブランド名を入力してください。')
})

const isLoraValidationInfo = async (inputData: RequestGenerateLoraType) => {
  try {
    if (inputData?.fileListBase64?.length <= 0) {
      message.error('画像を枚アップロードてください。')
      return false
    }
    let res = await createLoraValidation.validate(inputData, {
      abortEarly: false
    })
    return res
  } catch (error: any) {
    console.log(error)
    let errorMessages = error

    if (error.inner) {
      console.log(error.inner)
      errorMessages = _.keyBy(error.inner, 'path')
    }

    throw errorMessages
  }
}

export { createLoraValidation, isLoraValidationInfo }
