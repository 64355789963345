import { Flex, Row, Typography } from 'antd'
import React, { useContext } from 'react'
import { AppContext } from '../../../hooks/AppContext'

type Props = {}

const { Text } = Typography

const fontMobileStyle = {
  fontSize: 12
}

const LoraHeader = (props: Props) => {
  const { setMenuSelected, isMobile } = useContext(AppContext)

  return (
    <Row className='bg-[#5AAAFF] min-h-9 items-center mt-10 text-center w-full'>
      <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
        <Text strong className=' text-white' style={isMobile ? fontMobileStyle : { paddingLeft: 12 }}>
          アカウント
        </Text>
      </Flex>
      <Flex className='flex-[0.5]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
        <Text strong className=' text-white' style={isMobile ? fontMobileStyle : undefined}>
          アップ画像
        </Text>
      </Flex>
      <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
        <Text strong className=' text-white' style={isMobile ? fontMobileStyle : undefined}>
          生成日時
        </Text>
      </Flex>
      <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
        <Text strong className=' text-white' style={isMobile ? fontMobileStyle : undefined}>
          ブランド名
        </Text>
      </Flex>
      <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
        <Text strong className=' text-white ' style={isMobile ? fontMobileStyle : undefined}>
          モデル名
        </Text>
      </Flex>
      <Flex className='flex-[2]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
        <Text strong className=' text-white' style={isMobile ? fontMobileStyle : undefined}>
          プロフィール
        </Text>
      </Flex>
      <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
        <Text strong className=' text-white' style={isMobile ? fontMobileStyle : undefined}>
          分消費
        </Text>
      </Flex>
    </Row>
  )
}

export default LoraHeader
