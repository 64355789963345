import { Button, Row, DatePicker, ConfigProvider, Typography } from 'antd'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import ja from 'antd/locale/ja_JP'

type ScheduleType = { startTime: Date | string; endTime: Date | string }

type Props = {
  schedule: ScheduleType
  setSchedule: (value: ScheduleType) => void
  handleFilter: () => void
}

const { RangePicker }: any = DatePicker

const dateFormat = 'YYYY/MM/DD'

const MediaFilter: React.FC<Props> = (props) => {
  const { schedule, setSchedule, handleFilter } = props

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      setSchedule({
        startTime: new Date(dateStrings[0]),
        endTime: new Date(dateStrings[1])
      })
    }
  }

  return (
    <Row className='h-[130px] w-auto justify-between items-center mt-10 border-solid border-[1px] pl-10 pr-16 border-[#C2C2C2]'>
      <Row className='flex items-center justify-center'>
        <Typography className='mr-6 font-bold'>生成日時</Typography>
        <ConfigProvider locale={ja}>
          <RangePicker
            value={[dayjs(schedule.startTime), dayjs(schedule.endTime)]}
            onChange={onRangeChange}
            format={dateFormat}
            showToday={true}
          />
        </ConfigProvider>
      </Row>
      <Button className='w-[120px] font-bold bg-[#8C8C8C] font-size-[50px]' onClick={handleFilter} type='primary'>
        検索
      </Button>
    </Row>
  )
}

export default MediaFilter
