import { ConfigProvider, Spin } from 'antd'
import { RouterProvider } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

import './App.css'
import { AppContext } from './hooks/AppContext'
import router from './router'
import CommonAPIs from './controller/API/CommonAPIs'

function App() {
  const { isSpinning, contextHolder, showGenerateCompleted } = useContext(AppContext)
  const token = localStorage.getItem('token')
  const checkShowAlertRequestAIImagesCompletion = () => {
    if (token == null || token == undefined || token == '') {
      return
    }
    const quantity = localStorage.getItem('requestRenderAIImagesQuantity') ?? 0
    // console.log('Start tracking request AI images quantity', quantity)
    CommonAPIs.getRenderImgsProcessing().then((res) => {
      if (quantity && Number(quantity) > res?.length) {
        //Handle successed
        if (token != null && token != undefined && token != '') {
          showGenerateCompleted('画像が生成されました。画像生成履歴一覧で結果を確認してください。')
        }
      }
      localStorage.setItem('requestRenderAIImagesQuantity', res?.length)
    })
    // .catch((error) => showErrorAlert(error))
  }

  const checkShowAlertRequestTrainLoraCompletion = () => {
    if (token == null || token == undefined || token == '') {
      return
    }
    const quantity = localStorage.getItem('requestTrainLoraQuantity') ?? 0
    // console.log('Start tracking request train lora quantity', quantity)
    CommonAPIs.getLoraProcessing().then((res) => {
      // console.log('======LORA')
      // console.log(res)
      if (quantity && Number(quantity) > res?.data?.length) {
        //Handle successed
        if (token != null && token != undefined && token != '') {
          showGenerateCompleted('ブランド学習が完了しました。画像生成履歴一覧のブランド学習履歴で結果を確認できます。')
        }
      }
      localStorage.setItem('requestTrainLoraQuantity', res?.data?.length)
    })
    // .catch((error) => showErrorAlert(error))
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      checkShowAlertRequestAIImagesCompletion()
    }, 15000)

    return () => {
      // console.log('clearrrr tracking request AI images')
      clearInterval(timerId)
    }
  }, [])

  useEffect(() => {
    const timerId = setInterval(() => {
      checkShowAlertRequestTrainLoraCompletion()
    }, 15000)

    return () => {
      // console.log('clearrrr tracking request train lora')
      clearInterval(timerId)
    }
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Noto Sans JP'
        }
      }}
    >
      <Spin spinning={isSpinning} size='large' className='spinner'>
        <div style={{ height: window.innerHeight, backgroundColor: '#f6f6f6' }}>
          {contextHolder}
          <RouterProvider router={router} />
        </div>
      </Spin>
    </ConfigProvider>
  )
}

export default App
