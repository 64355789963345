import { Button, Col, Flex, Image, Row, Typography } from 'antd'
import Constant from '../../../controller/Constant'

type Props = {
  onSelectEditSystemPrompt: () => void
  systemPromptType: number
  mediaProcessingJobID: number
}

const EditSystemPromptView = (props: Props) => {
  const { systemPromptType, mediaProcessingJobID } = props

  const getCorrespondingPromptText = (): string => {
    switch (systemPromptType) {
      case Constant.systemPromptType.option1:
        return '写真の要素をインスパイアーで生成します。'
      case Constant.systemPromptType.option2:
        return '写真のプロダクトの要素を落とし込んで生成します。'
      case Constant.systemPromptType.option3:
        return 'アップロードされた画像に合うブランドプロフィール用の商品を作るプロンプトで生成します。'
      case Constant.systemPromptType.option4:
        return '生地の写真をアップしたらプロフィールに合致する画像を作成して生成します。'
      default:
        return ''
    }
  }

  return (
    <Flex align='center' style={{ marginTop: 30 }}>
      <img src={Constant.icon.bot} className='h-[44px] aspect-square mr-5' />
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 'bold'
        }}
      >
        {getCorrespondingPromptText()}
      </Typography>
      {!mediaProcessingJobID && (
        <img
          src={require('../../../assets/ic_edit.png')}
          style={{ width: 24, height: 24, cursor: 'pointer' }}
          onClick={props.onSelectEditSystemPrompt}
        />
      )}
    </Flex>
  )
}

export default EditSystemPromptView
