import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { Flex, Image, Typography } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { ImageType } from '../../../types'
import { Upload, UploadFile } from 'antd/lib'
import { UploadChangeParam } from 'antd/es/upload'
import { RcFile } from 'antd/lib/upload'
import Constant from '../../../controller/Constant'
import { AppContext } from '../../../hooks/AppContext'

type Props = {
  isDisabled?: boolean
  handleUploadChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined
  beforeUpload: ((file: RcFile, FileList: RcFile[]) => void) | undefined
  removeImage: (value: ImageType) => void
  fileListBase64: ImageType[]
}

const UploadImg2ImgDragger: React.FC<Props> = (props) => {
  const { isDisabled, handleUploadChange, removeImage, fileListBase64, beforeUpload } = props
  const { windowWidth } = useContext(AppContext)
  const [heightUpload, setHeightUpload] = useState<number>(350)
  const [widthUpload, setWidthUpload] = useState<number>(420)

  const uploadButton = (
    <Flex vertical justify='center' align='center'>
      <p className='flex text-[#CCCCCC] mt-2 whitespace-pre-line text-center'>
        {`ここに画像をドロップ\n-または-\nクリックしてアップロード`}
      </p>
    </Flex>
  )

  const isHasFileUpload = (): boolean => {
    if (fileListBase64?.length > 0) {
      return true
    }
    return false
  }

  useEffect(() => {
    setHeightUpload((434 * windowWidth) / 1440)
    setWidthUpload((434 * windowWidth) / 1440)
  }, [windowWidth])

  return (
    <Flex className='mb-5 mt-10 ' justify='center' vertical style={{ maxWidth: window.innerWidth }}>
      <Upload
        listType='picture'
        onChange={handleUploadChange}
        name='avatar'
        className='avatar-uploader'
        showUploadList={false}
        disabled={isDisabled}
        multiple
        beforeUpload={beforeUpload}
      >
        <Flex
          className='p-3 cursor-pointer border-dashed border-[#CCCCCC] rounded-[10px] border-[0.5px]'
          vertical
          style={{
            maxHeight: 434,
            maxWidth: 434,
            height: heightUpload,
            width: widthUpload,
            backgroundColor: isDisabled ? '#f6f6f6' : 'white'
          }}
          justify='center'
          align='center'
        >
          <Flex justify='center' align='center'>
            {uploadButton}
          </Flex>
        </Flex>
      </Upload>
      <Flex
        className='mt-6  items-center flex-wrap max-h-[400px] overflow-y-auto'
        style={{ maxWidth: 434, width: (434 * windowWidth) / 1440 }}
      >
        {fileListBase64?.map((item: ImageType, index: number) => {
          return (
            <Flex key={item?.uid} className='ml-1 mr-1 mb-4 relative'>
              <Image className='aspect-square object-cover' src={item?.url} height={97} />
              {!isDisabled && (
                <img
                  src={Constant.icon.closeTransparent}
                  className='w-4 aspect-square absolute text-white right-[2px] top-[2px] cursor-pointer z-50 object-cover'
                  onClick={() => removeImage(item)}
                />
              )}
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default UploadImg2ImgDragger
