import { useCallback, useContext, useState } from 'react'
import { message } from 'antd'
import { AppContext } from './AppContext'
import { CheckpointTypes, GenerateLoraType, ImageType } from '../types'
import CommonAPIs from '../controller/API/CommonAPIs'
import { v4 as uuidv4 } from 'uuid'
import Constant from '../controller/Constant'
import { RequestGenerateLoraType } from '../types/media_type'
import { isLoraValidationInfo } from '../validations/LoraValidation'
import { useNavigate } from 'react-router-dom'
import { UploadFile } from 'antd/lib'
import { UploadChangeParam } from 'antd/es/upload'
import { RcFile } from 'antd/lib/upload'

const MAX_PICTURE = 4

const useTrainLora = () => {
  const navigate = useNavigate()

  const { showProcessingAlert, showErrorApiAlert, setIsSpinning } = useContext(AppContext)
  const [isDisableCreateButton, setIsDisableCreateButton] = useState<boolean>(false)
  const [checkPointSelected, setCheckPointSelected] = useState<CheckpointTypes>({
    checkpoint: '',
    name: ''
  })
  const [checkPoints, setCheckPoints] = useState<CheckpointTypes[]>([])
  const [generatedLora, setGeneratedLora] = useState<GenerateLoraType>()
  const [loraProcessingJobID, setLoraProcessingJobID] = useState<number>(0)
  const [isStopLoop, setStopLoop] = useState(false)
  const [loraName, setLoraName] = useState<string>('')
  const [fileListBase64, setFileListBase64] = useState<ImageType[]>([])
  const [loraStatus, setLoraStatus] = useState<number>(0)
  const [isVisibleOptionModal, setIsVisibleOptionModal] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const [checkpointProfileEdit, setCheckpointProfileEdit] = useState<string>('')
  const [checkpointProfileValidate, setCheckpointProfileValidate] = useState<boolean>(false)
  const [isShowIntroduction, setIsShowIntroduction] = useState<boolean>(false)

  const checkIsDisabled = (): boolean => {
    if (loraProcessingJobID || isDisableCreateButton) {
      return true
    }
    return false
  }

  const isValidationInfo = async (data: RequestGenerateLoraType) => {
    try {
      const validData = await isLoraValidationInfo(data)
      setValidationErrors(null)
      return validData
    } catch (error) {
      setValidationErrors(error)
      return false
    }
  }

  const handleRequestTrainLora = async () => {
    const inputDt: RequestGenerateLoraType = {
      name: loraName.trim(),
      checkPointSelected: checkPointSelected?.checkpoint || checkPoints[0].checkpoint || '',
      fileListBase64: fileListBase64
    }

    let validRes = await isValidationInfo(inputDt)
    if (!validRes) {
      return
    }

    setIsDisableCreateButton(true)
    setStopLoop(false)
    return CommonAPIs.requestTrainLora(inputDt)
      .then((res) => {
        showProcessingAlert(
          'ブランドモデルの学習中です。AIサーバー状況によって処理過程に時間がかかることがあります。少々お待ち下さい。'
        )
        setCheckpointProfileEdit('')
        setCheckpointProfileValidate(false)
        setLoraProcessingJobID(res?.id)
        getLoraProcessing(res?.id)
        setLoraStatus(Constant.mediaProcessing.PROCESSING)
        return res
      })
      .catch((err: any) => showErrorApiAlert(err))
      .finally(() => setIsDisableCreateButton(false))
  }

  const getCheckpoint = () => {
    CommonAPIs.getCheckpointList()
      .then((res) => {
        setCheckPoints(res.data)
        console.log('========== GET LIST CHECKPOINT ==========')
        console.log(res.data)
        // setCheckPointSelected(res.data[0])
      })
      .catch((err: any) => {
        showErrorApiAlert(err)
      })
  }

  const getLoraProcessing = (loraProcessingJobID: number) => {
    CommonAPIs.checkStatusLoraJob(loraProcessingJobID)
      .then((res: GenerateLoraType) => {
        console.log('res::', res)
        if ([Constant.mediaProcessing.SUCCESS, Constant.mediaProcessing.FAIL].includes(res?.status)) {
          if ([Constant.mediaProcessing.SUCCESS].includes(res?.status)) {
            setLoraStatus(Constant.mediaProcessing.SUCCESS)
            setGeneratedLora(res)
          }
          if ([Constant.mediaProcessing.FAIL].includes(res?.status)) {
            setLoraStatus(Constant.mediaProcessing.FAIL)
          }
          setStopLoop(true)
          setLoraProcessingJobID(0)
        }
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const handleSelectCheckpoint = (checkopoint: any) => {
    setCheckPointSelected(checkopoint)
  }

  const handleUploadChange = useCallback(
    (info: UploadChangeParam<any>) => {
      const isImage = info?.file.type.startsWith('image/')

      if (!isImage) {
        return
      }

      const newFile: RcFile = info.file.originFileObj

      if (!fileListBase64.some((file) => file?.uid == newFile?.uid)) {
        const reader = new FileReader()
        reader.readAsDataURL(newFile)
        reader.onload = (e: any) => {
          setFileListBase64((prev: any) => {
            if (prev.some((file: any) => file?.uid == newFile?.uid)) {
              return prev
            }
            return [
              ...prev,
              {
                uid: newFile?.uid,
                url: e.target.result
              }
            ]
          })
        }
      }
    },
    [fileListBase64]
  )

  const beforeUpload = (file: RcFile) => {
    const isImage = file.type.startsWith('image/')
    if (!isImage) {
      message.error('アップロードは画像ファイルではありません')
      return
    }
  }

  const removeImage = (item?: ImageType) => {
    if (item) {
      const newData = fileListBase64?.filter((items) => items?.uid != item?.uid)
      setFileListBase64(newData)
      return
    }
    setFileListBase64([])
  }

  const handleSelfieWebcam = (base64: string) => {
    if (fileListBase64.length >= MAX_PICTURE) {
      message.error('写真を4枚撮ってください。')
      return
    }
    if (base64) {
      message.success('写真の撮影が成功になりました。')
      setFileListBase64((prev) => [
        ...prev,
        {
          uid: uuidv4(),
          url: base64
        }
      ])
    }
  }

  const onNavigation = () => {
    navigate('/text2img')
  }

  const updateCheckpointProfile = () => {
    if (checkpointProfileEdit == '' || checkpointProfileEdit == null) {
      setCheckpointProfileValidate(true)
      return
    }
    setCheckpointProfileValidate(false)
    setIsSpinning(true)
    return CommonAPIs.updateCheckpointProfile(generatedLora?.new_checkpoint ?? '', checkpointProfileEdit)
      .then((res) => {
        console.log('res:', res)
      })
      .catch((err) => showErrorApiAlert(err))
      .finally(() => setIsSpinning(false))
  }

  const checkShowIntroduction = () => {
    const isHideLoraIntro = localStorage.getItem(Constant.localStorageKeys.isHideLoraIntro)
    setIsShowIntroduction(isHideLoraIntro === 'true' ? false : true)

    localStorage.setItem(Constant.localStorageKeys.isHideLoraIntro, 'true')
  }

  return {
    loraProcessingJobID,
    getLoraProcessing,
    isStopLoop,
    checkIsDisabled,
    checkPointSelected,
    handleSelectCheckpoint,
    checkPoints,
    loraName,
    setLoraName,
    handleRequestTrainLora,
    handleUploadChange,
    beforeUpload,
    removeImage,
    handleSelfieWebcam,
    fileListBase64,
    getCheckpoint,
    loraStatus,
    isVisibleOptionModal,
    setIsVisibleOptionModal,
    generatedLora,
    validationErrors,
    onNavigation,
    checkpointProfileEdit,
    setCheckpointProfileEdit,
    updateCheckpointProfile,
    checkpointProfileValidate,
    isShowIntroduction,
    setIsShowIntroduction,
    checkShowIntroduction,
    isValidationInfo,
    setCheckpointProfileValidate,
    setLoraProcessingJobID,
    setLoraStatus
  }
}

export default useTrainLora
