import { Col, Row, Card } from 'antd'
import { useNavigate } from 'react-router-dom'
const { Meta } = Card

export default function Home() {
  const navigate = useNavigate()

  return (
    <div className='flex'>
      <div className='w-full h-full px-3 py-5'>
        <span className='text-[20px] font-bold'>何で画像生成を行いますか？</span>
        <Row className='mt-5' gutter={[16, 16]}>
          <Col span={6} className='pr-3'>
            <Card
              hoverable
              style={{ width: '100%', height: '100%' }}
              cover={<img alt='example' src={require('../assets/txt2img_home_label.png')} />}
              onClick={() => {
                navigate('/text2img')
              }}
            >
              <div className='text-center text-[16px] font-semibold'>テキストからデザイン提案</div>
            </Card>
          </Col>
          <Col span={6} className='pr-3'>
            <Card
              hoverable
              style={{ width: '100%', height: '100%' }}
              cover={
                <div className='flex flex-row bg-[#323539] p-1 rounded-tl-lg rounded-tr-lg'>
                  <img
                    alt='example'
                    className='w-[49%] mr-[1%]'
                    src={require('../assets/img2img_introduction_2.png')}
                  />
                  <img
                    alt='example'
                    className='w-[49%] ml-[1%]'
                    src={require('../assets/img2img_introduction_1.png')}
                  />
                </div>
              }
              onClick={() => {
                navigate('/img2img')
              }}
            >
              <div className='text-center text-[16px] font-semibold'>参考画像からデザイン提案</div>
            </Card>
          </Col>
          <Col span={6} className='pr-3'>
            <Card
              hoverable
              style={{ width: '100%', height: '100%' }}
              cover={<img alt='example' src={require('../assets/fabric_home_label.png')} />}
              onClick={() => {
                navigate('/change-material')
              }}
            >
              <div className='text-center text-[16px] font-semibold'>生地シミュレーション</div>
            </Card>
          </Col>
          <Col span={6} className='pr-3'>
            <Card
              hoverable
              style={{ width: '100%', height: '100%' }}
              cover={<img alt='example' src={require('../assets/lora_home_label.png')} />}
              onClick={() => {
                navigate('/train-lora')
              }}
            >
              <div className='text-center text-[16px] font-semibold'>ブランド学習</div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
