import { Layout, Breadcrumb, Flex, Button, Input, Typography, Spin } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { Content } from 'antd/es/layout/layout'
import Introduction from './components/Introduction'
import useTrainLora from '../../hooks/useTrainLora'
import CheckpointList from '../../components/Checkpoint/CheckpointList'
import UploadImageDragger from '../../components/GenerateImg/components/UploadImageDragger'
import { useEffect, useState } from 'react'
import TrainLoraSuccessModal from './components/TrainLoraSuccessModal'
import TrainLoraErrorModal from './components/TrainLoraErrorModal'
import Constant from '../../controller/Constant'
import LoadingModal from '../../components/common/LoadingModal'
import { useThreadStore } from '../../controller/store'
import { RequestGenerateLoraType } from '../../types/media_type'
import { useSearchParams } from 'react-router-dom'
import { addBeforeUnload, removeBeforeUnload } from '../../utils/utils'

export default function TrainLoraScreen() {
  const { setScreenKey } = useThreadStore()
  const [openModalError, setOpenModalError] = useState(false)
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const [loadingProcess, setLoadingProcess] = useState(0)
  const [errorMessageFile, setErrorMessageFile] = useState('')
  const [loading, setLoading] = useState(false)
  const {
    loraProcessingJobID,
    getLoraProcessing,
    isStopLoop,
    checkIsDisabled,
    checkPointSelected,
    handleSelectCheckpoint,
    checkPoints,
    loraName,
    setLoraName,
    handleRequestTrainLora,
    handleUploadChange,
    beforeUpload,
    removeImage,
    handleSelfieWebcam,
    fileListBase64,
    getCheckpoint,
    loraStatus,
    isVisibleOptionModal,
    setIsVisibleOptionModal,
    generatedLora,
    isValidationInfo,
    validationErrors,
    onNavigation,
    checkpointProfileEdit,
    setCheckpointProfileEdit,
    updateCheckpointProfile,
    checkpointProfileValidate,
    isShowIntroduction,
    checkShowIntroduction,
    setCheckpointProfileValidate,
    setLoraProcessingJobID,
    setLoraStatus
  } = useTrainLora()
  let [searchParams, setSearchParams] = useSearchParams()
  const jobId = searchParams.get('jobId')

  useEffect(() => {
    if (loraName || fileListBase64.length > 0) {
      addBeforeUnload()
      return
    }
    removeBeforeUnload()
  }, [loraName, fileListBase64.length])

  useEffect(() => {
    if (jobId) {
      updateLoadingPercent()
      setCheckpointProfileEdit('')
      setCheckpointProfileValidate(false)
      setLoraProcessingJobID(Number(jobId))
      getLoraProcessing(Number(jobId))
      setLoraStatus(Constant.mediaProcessing.PROCESSING)
      return
    }
  }, [jobId])

  useEffect(() => {
    setScreenKey(Constant.keyScreen.trainLora)
    getCheckpoint()
    checkShowIntroduction()
  }, [])

  const updateLoadingPercent = () => {
    if (loadingProcess >= 95) return
    setLoadingProcess((prev) => prev + 5)
  }

  useEffect(() => {
    if (loraProcessingJobID) {
      updateLoadingPercent()
      const timerId = setInterval(() => {
        updateLoadingPercent()
        getLoraProcessing(loraProcessingJobID)
      }, 5000)

      if (isStopLoop) {
        clearInterval(timerId)
      }

      return () => {
        clearInterval(timerId)
      }
    }
  }, [loraProcessingJobID, isStopLoop])

  useEffect(() => {
    if (loraStatus == Constant.mediaProcessing.SUCCESS) {
      setLoadingProcess(0)
      setOpenModalSuccess(true)
      setLoading(false)
      return
    }
    if (loraStatus == Constant.mediaProcessing.FAIL) {
      setLoadingProcess(0)
      setOpenModalError(true)
      setLoading(false)
      return
    }
  }, [loraStatus])

  useEffect(() => {
    if (fileListBase64.length) {
      setErrorMessageFile('')
    }
  }, [fileListBase64])

  const validate = () => {
    if (fileListBase64.length == 0) {
      setErrorMessageFile('※画像をアップしてからがブランドモデルを学習してください。')
      return false
    }
    if (fileListBase64.length < 4) {
      setErrorMessageFile('参考画像を４枚以上アップロードしてください。')
      return false
    }
    return true
  }

  const submit = async () => {
    if (loading) return
    const isValid = validate()
    if (!isValid) return
    try {
      const inputDt: RequestGenerateLoraType = {
        name: loraName.trim(),
        checkPointSelected: checkPointSelected?.checkpoint || checkPoints[0].checkpoint || '',
        fileListBase64: fileListBase64
      }

      let validRes = await isValidationInfo(inputDt)

      if (!validRes) {
        return
      }

      setLoading(true)
      updateLoadingPercent()

      const res = await handleRequestTrainLora()
      onTrainLoraSuccess(res.id)
      setLoading(false)
    } catch (error) {
      setLoadingProcess(0)
      setLoading(false)
    }
  }

  const onTrainLoraSuccess = (jobId: string) => {
    removeBeforeUnload();
    window.open(window.location.href + '?jobId=' + jobId, '_blank')
    window.location.reload()
  }
  return (
    <>
      <div className='flex'>
        <Sider width={400} style={{ background: '#f5f5f5' }}>
          <>
            <div
              className='w-[295px] bg-white pl-5 rounded-t-lg'
              style={{
                marginBottom: checkPointSelected && checkPointSelected.name ? 0 : 2,
                paddingBottom: 15
              }}
            >
              <p className='bg-[#323539] px-2 py-0.5 text-white rounded inline-block'>Step1</p>
              <div className='text-sm font-bold'>ベースモデルを選択</div>
            </div>

            <div className='w-[295px] h-[690px] overflow-scroll overflow-x-hidden bg-white pl-5 py-5'>
              <CheckpointList
                checkPoints={checkPoints}
                checkPointSelected={checkPointSelected}
                handleSelectCheckpoint={handleSelectCheckpoint}
              />
            </div>
          </>
        </Sider>
        <Layout style={{ paddingRight: 10 }}>
          <Content
            style={{
              margin: 0,
              minHeight: 280,
              height: '100%',
              width: 800,
              background: 'white',
              borderRadius: 10,
              padding: 20
            }}
          >
            <>
              <div className='bg-white pl-5 mb-1'>
                <p className='bg-[#323539] px-2 py-0.5 text-white rounded inline-block'>Step2</p>
                <div className='text-sm font-bold mb-4'>ブランド名を設定</div>
                <div className='mb-4'>
                  <Flex>
                    <label className='text-[16px] font-medium w-[100px] pt-[5px]'>ブランド名</label>
                    <div className='w-full'>
                      <Input
                        status={validationErrors?.name?.message ? 'error' : ''}
                        placeholder='ブランド名を入力してください'
                        onChange={(e: any) => setLoraName(e.target?.value)}
                      />
                      <Typography className='text-[12px]'>※半角英数字</Typography>

                      <Typography className='text-[12px] text-red-600 mt-1'>
                        {validationErrors?.name?.message}
                      </Typography>
                    </div>
                  </Flex>
                </div>
              </div>
            </>
            <>
              <div className='bg-white pl-5 mb-1'>
                <p className='bg-[#323539] px-2 py-0.5 text-white rounded inline-block'>Step 3</p>
                <div className='text-sm font-bold mb-2'>学習用の参考画像をアップロード</div>
                <div className='text-xs font-medium mb-4'>画像データは4枚以上から学習可能です</div>
                <Flex vertical>
                  <UploadImageDragger
                    isDisabled={checkIsDisabled()}
                    handleUploadChange={handleUploadChange}
                    beforeUpload={beforeUpload}
                    removeImage={removeImage}
                    fileListBase64={fileListBase64}
                    isVisibleOptionModal={isVisibleOptionModal}
                    setIsVisibleOptionModal={setIsVisibleOptionModal}
                  />
                  {errorMessageFile && <div className=' text-red-500 mb-5'>{errorMessageFile}</div>}
                </Flex>
              </div>
            </>
            <Flex justify={'center'} align={'center'}>
              <Button
                onClick={submit}
                className='w-[256px] border-none bg-[#323539] text-white disabled:bg-[#F6F6F6] disabled:text-[#CCCCCC]'
                disabled={fileListBase64.length == 0 && !loraName}
                style={{
                  height: 40,
                  width: 256,
                  borderRadius: 6
                }}
              >
                学習開始
              </Button>
            </Flex>
          </Content>
        </Layout>
        <LoadingModal open={loadingProcess > 0} percent={loadingProcess} content='ブランド学習中' />
        <TrainLoraSuccessModal
          value={checkpointProfileEdit}
          onChange={setCheckpointProfileEdit}
          onSubmit={updateCheckpointProfile}
          open={openModalSuccess}
          onClose={() => {
            window.location.href = window.location.origin + window.location.pathname
          }}
          generatedLora={generatedLora}
        />
        <TrainLoraErrorModal open={openModalError} onClose={() => setOpenModalError(false)} reSubmit={submit} />
      </div>
      {isShowIntroduction && <Introduction />}
    </>
  )
}
