import { Button, Col, Row, Typography } from 'antd'
import React, { useState } from 'react'

type Props = {
  setTab: (value: number) => void
}

const HistoryType = (props: Props) => {
  const [tab, setTab] = useState(1)

  return (
    <Row style={{ marginTop: 20 }}>
      <Col style={{ width: 84, marginRight: 26 }}>
        <Button
          style={{
            padding: 0,
            borderColor: 'white'
          }}
          onClick={() => {
            setTab(1)
            props.setTab(1)
          }}
        >
          画像生成履歴
        </Button>
        <Row style={{ height: 6, background: tab === 1 ? '#6AAFE6' : 'white' }} />
      </Col>
      <Col style={{ width: 115, marginRight: 26 }}>
        <Button
          style={{
            padding: 0,
            borderColor: 'white'
          }}
          onClick={() => {
            setTab(2)
            props.setTab(2)
          }}
        >
          ブランド学習履歴
        </Button>
        <Row style={{ height: 6, background: tab === 2 ? '#6AAFE6' : 'white' }} />
      </Col>
      <Col style={{ width: 127 }}>
        <Button
          style={{
            padding: 0,
            borderColor: 'white'
          }}
          onClick={() => {
            setTab(3)
            props.setTab(3)
          }}
        >
          商品説明文生成履歴
        </Button>
        <Row style={{ height: 6, background: tab === 3 ? '#6AAFE6' : 'white' }} />
      </Col>
    </Row>
  )
}

export default HistoryType
