import moment from 'moment'
let isBeforeUnloadPrevent = null
export const formatDateWithType = (date: string | Date, typeFormat?: string, locale: string = 'ja') => {
  const today = moment(new Date(date))
    .locale(locale)
    .format(typeFormat || 'YYYY-MM-DD')
  return today
}

export const formatStringDateWithType = (date: string | Date, typeFormat?: string, locale: string = 'ja') => {
  const today = moment(date)
    .locale(locale)
    .format(typeFormat || 'YYYY-MM-DD')
  return today
}

//Viết 1 hàm để format number hoặc string sang dạng 100,000,000
export const formatNumber = (number: number | string) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const handleBeforeUnload = (e: any) => {
  const confirmationMessage = '行った変更が保存されない可能性があります'
  e.returnValue = confirmationMessage
  return confirmationMessage
}

export const addBeforeUnload = () => {
  (window as any).isBeforeUnloadPrevent = true
  window.addEventListener('beforeunload', handleBeforeUnload)
}

export const removeBeforeUnload = () => {
  (window as any).isBeforeUnloadPrevent = false
  window.removeEventListener('beforeunload', handleBeforeUnload)
}
