import { createBrowserRouter } from 'react-router-dom'
import BaseLayout from '../pages/layouts/BaseLayout'
import RenderHistoryScreen from '../components/RenderHistory/RenderHistoryScreen'
import LoginScreen from '../components/Auth/LoginScreen'
import ChangeMaterialScreen from '../components/Material/ChangeMaterialScreen'
import DetailLayout from '../pages/layouts/DetailLayout'
import TrainLoraScreen from '../screens/trainLora/TrainLoraScreen'
import Text2ImgScreen from '../screens/txt2img/Txt2imgScreen'
import TrainLoraScreen2 from '../components/GenerateImg/TrainLoraScreen'
import Home from '../screens/Home'
import Img2ImgScreen2 from '../components/Img2Img/Img2ImgScreen'
import Img2ImgScreen from '../components/Img2Img/Img2ImgScreen'
import CreateProductContentScreen from '../screens/productContent/CreateProductContentScreen'
import RegisterScreen from '../components/Auth/RegisterScreen'
import ForgotPasswordScreen from '../components/Auth/ForgotPassword/ForgotPasswordScreen'

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginScreen />
  },
  {
    path: '/register',
    element: <RegisterScreen />
  },
  {
    path: 'forgot-password',
    element: <ForgotPasswordScreen />
  },
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'render-history',
        element: <RenderHistoryScreen />
      }
    ]
  },
  {
    // path: '/',
    element: <DetailLayout />,
    children: [
      {
        path: 'train-lora',
        element: <TrainLoraScreen />
      },
      {
        path: 'img2img',
        element: <Img2ImgScreen />
      },
      {
        path: 'train-lora2',
        element: <TrainLoraScreen2 />
      },
      {
        path: 'text2img',
        element: <Text2ImgScreen />
      },
      {
        path: 'change-material',
        element: <ChangeMaterialScreen />
      },
      {
        path: 'img2img2',
        element: <Img2ImgScreen2 />
      },
      {
        path: 'create-product-content',
        element: <CreateProductContentScreen />
      }
    ]
  }
])

export default router
