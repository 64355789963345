import React, { useEffect, useContext, useState, useCallback } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Col, Row, Select, Flex, Image, message, Segmented } from 'antd'
import SubUploadImg2Img from './SubUploadImg2Img'
import useMaterial from '../../../hooks/useMaterial'
import { ImageType } from '../../../types'
import { CategoryType, FabricCoordinationResult } from '../../../types/media_type'
import CommonAPIs from '../../../controller/API/CommonAPIs'
import { AppContext } from '../../../hooks/AppContext'
import InpaintScreen from '../../InpaintScreen'
import AutoSegment from '../../AutoSegment'
import CanvasMask from '../../CanvasMask'

type Props = {
  backgroundImage: ImageType
  setClothesImg: (file: ImageType) => void
  onNextStep: (img: string, fabricImg: string, category: string, maskImg: string, batchSize: string) => void
}

const FabricCoordination: React.FC<Props> = (props) => {
  const { showErrorApiAlert } = useContext(AppContext)
  const { backgroundImage, setClothesImg, onNextStep } = props
  const { beforeUpload, fileBase64, setFileBase64, imgIndexSelected, setImgIndexSelected } = useMaterial()
  const [selectionToolType, setSelectionToolType] = useState(1)
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [category, setCategory] = useState<string>('cotton/linen fabric')
  const [isModalSegmentManualOpen, setIsModalSegmentManualOpen] = useState(false)
  const [isModalSegmentAutoOpen, setIsModalSegmentAutoOpen] = useState(false)
  const [image, setImage] = useState(backgroundImage.url)
  const [maskImage, setMaskImage] = useState('')
  const [isDrawn, setIsDrawn] = useState(false)
  const [fabrics, setFabrics] = useState<string[]>([])
  const [batchSize, setBatchSize] = useState('4')

  useEffect(() => {
    CommonAPIs.getCategories()
      .then((res) => {
        console.log('======== Categories: ', res.data)
        setCategories(res.data)
      })
      .catch((err) => showErrorApiAlert(err))

    CommonAPIs.getFabrics()
      .then((res) => {
        let fabrics = Object.entries(res.data)
        let fabricURLs = fabrics.map((item: any) => item[1])
        setFabrics(fabricURLs)
      })
      .catch((err) => showErrorApiAlert(err))
  }, [])

  const nextStep = () => {
    console.log('maskImage herreee:', maskImage)

    if (!isDrawn) {
      message.error('生地を変更したいエリアを選択してください。')
      return
    }

    onNextStep(
      image,
      fileBase64.url !== '' ? fileBase64.url : fabrics[imgIndexSelected ?? 0],
      category,
      maskImage,
      batchSize
    )
  }

  const handleCancelCrop = useCallback(() => {
    setFileBase64({ uid: '', url: '' })
  }, [])

  const handleUploadImg = useCallback((file: ImageType) => {
    setFileBase64(file)
    setImgIndexSelected(null)
  }, [])

  return (
    <Row
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 50
      }}
    >
      <Col span='5' className='py-6 px-4'>
        <Flex className='h-36 py-7 flex-wrap'>
          <span className='w-full h-5 my-0 py-0'>カテゴリー</span>
          <Select
            className='w-full h-[60px] '
            defaultValue={'コットン・麻生地'}
            value={category}
            onChange={(e) => setCategory(e)}
            options={categories.map((item) => ({ label: item.name_jp, value: item.name }))}
          />
        </Flex>
        <Flex className='flex-wrap'>
          <span className='w-full mb-5'>生地の種類</span>
          <Row className='w-full'>
            {fabrics?.map((fabric, index) => {
              return (
                <Col span={12} className='pr-2 pb-2'>
                  <Image
                    src={fabric}
                    alt=''
                    style={{
                      aspectRatio: 1,
                      objectFit: 'cover',
                      border:
                        fileBase64.url === '' &&
                        (index == imgIndexSelected || (imgIndexSelected === null && index === 0))
                          ? '3px solid #3182CE'
                          : 'none'
                    }}
                    preview={false}
                    onClick={() => {
                      setFileBase64({ uid: '', url: '' })
                      setImgIndexSelected(index)
                    }}
                  />
                </Col>
              )
            })}
          </Row>
        </Flex>
        <Flex className='flex flex-col pt-10 min-h-[300px]'>
          <span className='w-full h-10'>自分で生地をアップロードする</span>
          {fileBase64.url ? (
            <Col className='self-center'>
              <Image src={fileBase64.url} width={220} height='auto' />
              <CloseCircleOutlined
                onClick={() => {
                  setFileBase64({ uid: '', url: '' })
                }}
                style={{ zIndex: 999, position: 'absolute', right: 5, top: 5 }}
              />
            </Col>
          ) : (
            <SubUploadImg2Img
              onUploadImg={handleUploadImg}
              onCancelCrop={handleCancelCrop}
              beforeUpload={beforeUpload}
            />
          )}
          <Flex className='w-full mt-5 flex justify-center content-center'>
            <span className='self-center font-bold'>画像作成</span>
            <Select
              className='w-15 self-center ml-4'
              defaultValue='4'
              onChange={(val) => setBatchSize(val)}
              options={[
                { value: '1', label: '1枚' },
                { value: '2', label: '2枚' },
                { value: '3', label: '3枚' },
                { value: '4', label: '4枚' }
              ]}
            />
          </Flex>
          <Flex className='w-full flex justify-center content-center pt-10'>
            <button
              className='w-[300px] h-14 bg-sky-500 text-white border-0 hover:cursor-pointer'
              style={{ background: '#323539', borderRadius: 9 }}
              onClick={nextStep}
            >
              生地スケール調整
            </button>
          </Flex>
        </Flex>
      </Col>
      <Col span='19'>
        <Flex className='flex-wrap w-full'>
          <Flex className='flex-wrap py-7 px-16 w-full'>
            <span className='w-full my-3'>選択エリアツール</span>
            <Segmented
              options={[
                {
                  label: (
                    <div
                      className={`w-[180px] h-[48px] text-nowrap hover:cursor-pointer flex justify-center mr-5 bg-white rounded-lg`}
                      onClick={() => {
                        setSelectionToolType(1)
                        setIsModalSegmentAutoOpen(true)
                      }}
                    >
                      <img
                        src={
                          selectionToolType == 1
                            ? require('../../../assets/ic-auto-segment-enable.png')
                            : require('../../../assets/ic-auto-segment-disable.png')
                        }
                        alt=''
                        className='mr-2 self-center'
                        style={{
                          height: 20,
                          aspectRatio: 1
                        }}
                      />
                      <span
                        className='self-center'
                        style={{
                          color: selectionToolType === 1 ? '#000000' : '#CCCCCC'
                        }}
                      >
                        セグメントツール
                      </span>
                    </div>
                  ),
                  value: 'autoSegment'
                },
                {
                  label: (
                    <div
                      className={`w-[180px] h-[48px] text-nowrap hover:cursor-pointer flex justify-center mr-5 bg-white rounded-lg`}
                      onClick={() => {
                        setSelectionToolType(2)
                        setIsModalSegmentManualOpen(true)
                      }}
                    >
                      <img
                        src={
                          selectionToolType != 1
                            ? require('../../../assets/ic-manual-segment-enable.png')
                            : require('../../../assets/ic-manual-segment-disable.png')
                        }
                        alt=''
                        className='mr-2 self-center'
                        style={{
                          height: 20,
                          aspectRatio: 1
                        }}
                      />
                      <span
                        style={{
                          color: selectionToolType != 1 ? '#000000' : '#CCCCCC'
                        }}
                        className='self-center'
                      >
                        ペイントツール
                      </span>
                    </div>
                  ),
                  value: 'manualSegment'
                }
              ]}
              onChange={(value) => {
                console.log(value) // string
              }}
              style={{
                height: 56,
                borderWidth: 1,
                border: '1px solid #CCCCCC',
                background: 'white',
                borderRadius: 12,
                paddingInline: 4
              }}
            />
          </Flex>
          <div className='w-full flex flex-row-reverse pr-[20%]'>
            <img
              onClick={() => setClothesImg({ uid: '', url: '' })}
              src={require('../../../assets/ic-close.png')}
              style={{
                width: 24,
                aspectRatio: 1
              }}
            />
          </div>
          <div className='w-full flex justify-center mt-5 relative'>
            <img src={backgroundImage.url} width={500} />
            {maskImage && <CanvasMask maskImage={maskImage} />}
          </div>
        </Flex>
      </Col>
      <InpaintScreen
        isVisibleInPaint={isModalSegmentManualOpen}
        closeInPaint={() => setIsModalSegmentManualOpen(false)}
        mediaSelectedInfo={backgroundImage.url}
        setMediaSelectedInfo={() => {}}
        onApplyImage={(result: string, maskImage: string) => {
          setImage(result)
          setMaskImage(maskImage)
          setIsDrawn(true)
        }}
      />
      <AutoSegment
        isOpen={isModalSegmentAutoOpen}
        onClose={() => setIsModalSegmentAutoOpen(false)}
        imageURL={backgroundImage.url}
        onApply={(result: string, maskImage: string) => {
          setImage(result)
          setMaskImage(maskImage)
          setIsDrawn(true)
          setIsModalSegmentAutoOpen(false)
        }}
      />
    </Row>
  )
}

export default FabricCoordination
