import { Col, Row, Image, Flex } from 'antd'
import Typography from 'antd/es/typography/Typography'
import { Layout } from 'antd/lib'
import React, { useState } from 'react'
import ImageGrid from './ImageGrid'
import ThreeImgsLayout from './ThreeImgsLayout'
import PreviewImagesModal from './PreviewImagesModal'

type Props = {
  images: string[]
  profile: string
}

const ImgsLayout = (props: Props) => {
  const [isPreview, setIsPreview] = useState(false)

  const onShowPreview = () => {
    setIsPreview(true)
  }

  const getImages = () => {
    return props.images.map((img, index) => {
      return {
        url: img
      }
    })
  }

  return (
    <>
      {props.images.length == 3 ? (
        <ThreeImgsLayout profile={props.profile} images={props.images} onClick={onShowPreview} />
      ) : (
        <Layout style={{ background: 'white', alignItems: 'center', paddingBottom: 40 }}>
          <Typography className='font-bold' style={{ marginTop: 40, fontSize: 12 }}>
            {props.profile}
          </Typography>
          <Flex
            style={{
              width: '50%',
              aspectRatio: '3/2',
              display: 'flex',
              marginTop: 40,
              flexDirection: 'column'
            }}
            onClick={onShowPreview}
          >
            <ImageGrid images={props.images} />
          </Flex>
        </Layout>
      )}
      <PreviewImagesModal imageList={getImages()} indexPreview={0} isPreview={isPreview} setIsPreview={setIsPreview} />
    </>
  )
}

export default ImgsLayout
