import { Button, Flex, Modal, Row, Spin, Radio, Space } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import CommonAPIs from '../controller/API/CommonAPIs'
import html2canvas from 'html2canvas'
import { overlayImages, removeBackgroundWithMask, resizeImage } from '../utils/ImageHandler'
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined'

interface Point {
  x: number
  y: number
}

interface Box {
  start: Point
  end: Point
}

type Props = {
  imageURL: string
  isOpen: boolean
  onClose: () => void
  onApply: any
}

const MODE = {
  BOX: 'box',
  CLICK: 'click'
}
const AutoSegment = (props: Props) => {
  const { imageURL, isOpen, onClose, onApply } = props

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<any>(null)
  const [box, setBox] = useState<Box | null>(null)
  const [drawing, setDrawing] = useState<boolean>(false)
  const [startPoint, setStartPoint] = useState<Point | null>(null)
  const [coordinates, setCoordinates] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(0)
  const [resizedImage, setResizedImage] = useState('')
  const [maskImage, setMaskImage] = useState('')

  const [mode, setMode] = useState(MODE.BOX)

  useEffect(() => {
    if (!isOpen) return
    loadImageToCanvas(imageURL)
  }, [isOpen, imageURL])

  const loadImageToCanvas = (image: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = image

      img.onload = () => {
        imageRef.current = img
        let width, height
        if (img.height > 768 || img.width > 576) {
          if (img.height > 768) {
            height = 768
            width = (768 * img.width) / img.height
          }
          if (img.width > 576) {
            width = 576
            height = (576 * img.height) / img.width
          }
        } else {
          width = img.width
          height = img.height
        }

        setImageWidth(width as number)
        setImageHeight(height as number)
        drawImageAndBox(width as number, height as number)
        setMaskImage('')
        resolve({ width, height })
      }

      img.onerror = (error) => {
        reject(error)
      }
    })
  }

  const drawImageAndBox = (width: number, height: number) => {
    const canvas = canvasRef.current
    const image = imageRef.current
    if (!canvas || !image) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    canvas.width = width
    canvas.height = height
    ctx.drawImage(image, 0, 0, width, height)

    if (!resizedImage) {
      setResizedImage(canvas.toDataURL())
    }

    if (box && mode == MODE.BOX) {
      drawBoxOnCanvas(ctx, box)

      const { start, end } = box
      const scaleX = image.width / width
      const scaleY = image.height / height
      setCoordinates({
        x1: start.x * scaleX,
        y1: start.y * scaleY,
        x2: end.x * scaleX,
        y2: end.y * scaleY
      })
    }
  }

  const drawBoxOnCanvas = (ctx: CanvasRenderingContext2D, box: Box) => {
    const { start, end } = box
    const width = end.x - start.x
    const height = end.y - start.y

    ctx.beginPath()
    ctx.strokeStyle = 'red'
    ctx.lineWidth = 1
    ctx.rect(start.x, start.y, width, height)
    ctx.stroke()
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const point: Point = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
    console.log('point:', point)

    if (mode == MODE.BOX) {
      setDrawing(true)
      setStartPoint(point)
      setBox({ start: point, end: point })
    }
    if (mode == MODE.CLICK) {
      inferencePointClick(point)
    }
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!drawing || !startPoint) return

    const point: Point = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
    setBox((prevBox) => (prevBox ? { start: prevBox.start, end: point } : null))

    drawImageAndBox(imageWidth, imageHeight)
  }

  const handleMouseUp = () => {
    inference()
  }

  const inference = async () => {
    if (!coordinates) return
    if (coordinates.x2 - coordinates.x1 < 10 || coordinates.y2 - coordinates.y1 < 10) {
      setDrawing(false)
      setStartPoint(null)
      return
    }
    setLoading(true)
    try {
      const res = await CommonAPIs.renderMask(imageURL, coordinates.x1, coordinates.y1, coordinates.x2, coordinates.y2)
      const base64Mask = 'data:image/png;base64,' + res.base64_mask_img

      const combinedMask = await overlayImages(base64Mask, maskImage)
      console.log('combinedMask:', combinedMask)

      const newImage = await removeBackgroundWithMask(imageURL, combinedMask)
      await setBox(() => null)
      await loadImageToCanvas(newImage)

      setMaskImage(combinedMask)
    } catch (error) {
    } finally {
      setLoading(false)
      setCoordinates(null)
      setBox(null)
    }
  }

  const inferencePointClick = async (point: any) => {
    const image = imageRef.current
    const scaleX = image.width / imageWidth
    const scaleY = image.height / imageHeight
    const scaledPoint = {
      x: point.x * scaleX,
      y: point.y * scaleY
    }
    setLoading(true)
    try {
      const res = await CommonAPIs.inferencePointClick(imageURL, scaledPoint)
      const base64Mask = 'data:image/png;base64,' + res.base64_mask_img

      const combinedMask = await overlayImages(base64Mask, maskImage)
      console.log('combinedMask:', combinedMask)

      const newImage = await removeBackgroundWithMask(imageURL, combinedMask)
      await setBox(() => null)
      await loadImageToCanvas(newImage)

      setMaskImage(combinedMask)
    } catch (error) {
      console.log('error:', error)
    } finally {
      setLoading(false)
      setCoordinates(null)
      setBox(null)
    }
  }
  const apply = async () => {
    if (maskImage) {
      const newImage = await removeBackgroundWithMask(imageURL, maskImage)
      const resizedImage = await resizeImage(newImage)
      onApply(resizedImage, maskImage)
    } else {
      onApply(resizedImage)
    }
  }

  return (
    <Modal open={isOpen} onCancel={onClose} footer={null} width={1000} centered closeIcon={<CloseCircleOutlined className='text-black'/>}>
      <Spin spinning={loading}>
        <div className='absolute'>
          <Radio.Group onChange={(e) => setMode(e.target.value)} value={mode}>
            <Space direction='vertical'>
              <Radio.Button className='flex text-xs items-center' value='box'>
                ボックスでセグメント取る
              </Radio.Button>
              <Radio.Button className='flex text-xs items-center' value='click'>
                クリックでセグメント取る
              </Radio.Button>
            </Space>
          </Radio.Group>
        </div>
        <div onMouseUp={handleMouseUp}>
          <Flex vertical align='center'>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <img
                id='sourceImageAuto'
                ref={imageRef}
                style={{ userSelect: 'none', opacity: 0, width: imageWidth, height: imageHeight }}
              />
              <canvas
                ref={canvasRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                style={{ position: 'absolute', top: 0, left: 0, width: imageWidth, height: imageHeight }}
              />
            </div>
          </Flex>
          <Row className='w-full flex justify-center'>
            <Button
              onClick={() => loadImageToCanvas(imageURL)}
              style={{
                alignItems: 'center',
                marginBottom: 15,
                borderColor: 'black',
                color: 'black',
                width: 120,
                marginRight: 12
              }}
            >
              リセット
            </Button>
            <Button
              onClick={apply}
              style={{
                alignItems: 'center',
                marginBottom: 15,
                borderColor: 'black',
                color: 'black',
                width: 150
              }}
            >
              決定する
            </Button>
          </Row>
        </div>
      </Spin>
    </Modal>
  )
}

export default AutoSegment
