import { Button, Image, Row, Typography, Flex, Spin } from 'antd'
import moment from 'moment'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { AppContext } from '../../../hooks/AppContext'
import { PromptType, RenderHistoryItemType } from '../../../types/media_type'
import { LoadingOutlined } from '@ant-design/icons'
import PreviewImagesModal from '../../Img2Img/components/PreviewImagesModal'

type Props = {
  item: RenderHistoryItemType
  isLoraProcessing?: boolean
}

const LoraItem: React.FC<Props> = (props) => {
  const { item, isLoraProcessing } = props
  const { isMobile } = useContext(AppContext)
  const parentRef: any = useRef()
  const [heightParent, setHeightParent] = useState<number>(0)
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false)
  const [imagesPreview, setImagesPreview] = useState<{ url: string }[]>([])

  const fontMobileStyle = {
    fontSize: 12,
    marginRight: 5,
    height: '100%'
  }

  const getSystemPromptTitle = (value: number) => {
    switch (value) {
      case 1:
        return 'スタイル: 写真の要素をインスパイアー\n\n'

      case 2:
        return 'スタイル: 写真のプロダクトの要素を落とし込む\n\n'

      case 3:
        return 'スタイル: アップロードされた画像に合うブランドプロフィール用の商品を作るプロンプト\n\n'

      case 4:
        return 'スタイル: 生地の写真をアップしたらプロフィールに合致する画像を作成する\n\n'

      default:
        break
    }
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      setHeightParent(parentRef.current?.offsetHeight)
    }

    const height = parentRef?.current?.offsetHeight
    setHeightParent(height)

    setTimeout(() => {
      const newHeight = parentRef?.current?.offsetHeight
      if (height !== newHeight) {
        setHeightParent(newHeight)
      }
    }, 2000)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Row className='min-h-9 items-center text-center w-full h-[90px]'>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography.Paragraph style={isMobile ? fontMobileStyle : { textAlign: 'left' }}>
            {item.user?.name ?? item?.user?.email}
          </Typography.Paragraph>
        </Flex>
        <Flex className='flex-[0.5]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <>
            {Object.values(item?.images ?? []).length > 0 && (
              <Flex className=''>
                <Image
                  preview={false}
                  onClick={() => {
                    setImagesPreview((item?.images ?? []).map((url) => ({ url })))
                    setIsShowPreview(true)
                  }}
                  src={item?.images![0]}
                  height={60}
                  style={{ width: 60, objectFit: 'cover' }}
                />
              </Flex>
            )}
          </>
        </Flex>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          {isLoraProcessing ? (
            <img src={require('../../../assets/loading.gif')} width={100} />
          ) : (
            <Typography style={isMobile ? fontMobileStyle : { textAlign: 'left' }}>
              {moment(item?.created_at).format('YYYY/MM/DD HH:mm')}
            </Typography>
          )}
        </Flex>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography style={isMobile ? fontMobileStyle : { textAlign: 'left' }}>{item.name}</Typography>
        </Flex>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography style={isMobile ? fontMobileStyle : { textAlign: 'left' }} className=''>
            {item?.category ?? item?.checkpoint}
          </Typography>
        </Flex>
        <Flex className='flex-[2]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography.Paragraph style={isMobile ? fontMobileStyle : { textAlign: 'left' }} className=''>
            {item?.description
              ? item?.description
              : item?.profile_jp
              ? item?.profile_jp
              : item?.checkpoint_profile?.profile_jp}
          </Typography.Paragraph>
        </Flex>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography.Paragraph style={isMobile ? fontMobileStyle : { textAlign: 'left' }} className=''>
            {Number(item?.time_second / 60).toFixed(0)}分消費
          </Typography.Paragraph>
        </Flex>
      </Row>
      <Row className='h-[1px] bg-[#5AAAFF]' />
      <PreviewImagesModal
        imageList={imagesPreview ?? []}
        indexPreview={0}
        isPreview={isShowPreview}
        setIsPreview={setIsShowPreview}
      />
    </>
  )
}

export default LoraItem
