import { useCallback, useState, useContext } from 'react'
import { message } from 'antd'
import { ImageType, CategoryType } from '../types/media_type'
import { UploadChangeParam } from 'antd/es/upload'
import { AppContext } from './AppContext'
import { RcFile } from 'antd/lib/upload'
import CommonAPIs from '../controller/API/CommonAPIs'

const useMaterial = () => {
  const { showErrorApiAlert } = useContext(AppContext)
  const [fileBase64, setFileBase64] = useState<ImageType>({ uid: '', url: '' })
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [imgIndexSelected, setImgIndexSelected] = useState<number | null>(null)

  const getCategories = (): void => {
    CommonAPIs.getCategories()
      .then((res) => {
        return res.data
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const handleUploadChange = useCallback(
    (info: UploadChangeParam<any>) => {
      const isImage = info?.file.type.startsWith('image/')

      if (!isImage) {
        return
      }

      const newFile: RcFile = info.file.originFileObj
      const reader = new FileReader()
      reader.readAsDataURL(newFile)
      reader.onload = (e: any) => {
        setFileBase64(() => {
          return {
            uid: newFile?.uid,
            url: e.target.result
          }
        })
        setImgIndexSelected(null)
      }
    },
    [fileBase64]
  )

  const beforeUpload = useCallback((file: RcFile) => {
    const isImage = file.type.startsWith('image/')
    if (!isImage) {
      message.error('アップロードは画像ファイルではありません')
      return
    }
  }, [])

  return {
    handleUploadChange,
    beforeUpload,
    fileBase64,
    setFileBase64,
    getCategories,
    categories,
    imgIndexSelected,
    setImgIndexSelected
  }
}

export default useMaterial
