import { Col, Row, Image, Flex } from 'antd'
import Typography from 'antd/es/typography/Typography'
import { Layout } from 'antd/lib'
import React from 'react'

type Props = {
  images: string[]
  profile: string
  onClick?: () => void
}

const ThreeImgsLayout = (props: Props) => {
  const { images } = props
  const size = 600

  return (
    <Layout style={{ background: 'white', alignItems: 'center' }}>
      <Typography className='font-bold' style={{ marginTop: 40, fontSize: 12 }}>
        {props.profile}
      </Typography>
      <Col
        style={{
          width: '50%',
          aspectRatio: '3/2',
          display: 'flex',
          marginTop: 40
        }}
        onClick={props.onClick}
      >
        <Flex
          style={{
            flex: 2 / 3,
            height: '100%',
            display: 'flex',
            marginRight: 8
          }}
        >
          <img
            src={props.images[0]}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 8
            }}
          />
        </Flex>
        <Flex
          style={{
            flex: 1 / 3,
            height: '100%',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <img
            src={props.images[1]}
            style={{
              width: '100%',
              aspectRatio: 1,
              objectFit: 'cover',
              borderRadius: 8
            }}
          />
          <img
            src={props.images[2]}
            style={{
              width: '100%',
              aspectRatio: 1,
              objectFit: 'cover',
              borderRadius: 8
            }}
          />
        </Flex>
      </Col>
    </Layout>
  )
}

export default ThreeImgsLayout
