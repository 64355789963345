import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex, Image, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ImageType } from '../../../types'
import { Upload, UploadFile } from 'antd/lib'
import { UploadChangeParam } from 'antd/es/upload'
import { RcFile } from 'antd/lib/upload'

type Props = {
  isDisabled?: boolean
  handleUploadChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined
  beforeUpload: ((file: RcFile, FileList: RcFile[]) => void) | undefined
  removeImage: (value?: ImageType) => void
  fileListBase64: ImageType[]
  isVisibleOptionModal: boolean
  setIsVisibleOptionModal: (value: boolean) => void
}

const UploadImageDragger: React.FC<Props> = (props) => {
  const { isDisabled, handleUploadChange, removeImage, fileListBase64, beforeUpload } = props
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [heightUpload, setHeightUpload] = useState<number>(350)
  const [widthUpload, setWidthUpload] = useState<number>(420)

  const uploadButton = (
    <Flex vertical justify='center' align='center'>
      <PlusOutlined className='text-[30px] text-[#6B7280]' />
      <p className='flex text-[#6B7280] mt-2 whitespace-pre-line text-center'>{`画像アップロード`}</p>
    </Flex>
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const isHasFileUpload = (): boolean => {
    if (fileListBase64?.length > 0) {
      return true
    }
    return false
  }

  const clearImg = (e: any) => {
    e.stopPropagation()
    removeImage()
  }
  useEffect(() => {
    setHeightUpload(((isHasFileUpload() ? 176 : 350) * windowWidth) / 750)
    setWidthUpload(((isHasFileUpload() ? 296 : 420) * windowWidth) / 750)
  }, [windowWidth])

  return (
    <Flex
      className='mb-2 border border-dashed border-[#D9D9D9] rounded-xl p-3 w-full min-h-[450px] relative'
      style={{ maxWidth: window.innerWidth }}
    >
      <Flex className='flex-wrap overflow-y-auto'>
        <Upload
          listType='picture'
          onChange={handleUploadChange}
          name='avatar'
          className='avatar-uploader '
          showUploadList={false}
          disabled={isDisabled}
          multiple
          beforeUpload={beforeUpload}
        >
          <Flex
            className='p-3 cursor-pointer border-dashed border-[#6B7280] rounded-[10px] border-[0.5px] mr-2'
            vertical
            style={{
              maxHeight: 164,
              maxWidth: 164,
              height: heightUpload,
              width: widthUpload,
              backgroundColor: isDisabled ? '#f6f6f6' : 'white'
            }}
            justify='center'
            align='center'
          >
            <Flex justify='center' align='center'>
              {uploadButton}
            </Flex>
          </Flex>
          {fileListBase64.length > 0 && (
            <span className='absolute right-3 bottom-3 cursor-pointer' onClick={clearImg}>
              一括削除
            </span>
          )}
        </Upload>
        {fileListBase64?.map((item: ImageType, index: number) => {
          return (
            <Flex key={item?.uid} className='mr-2 mb-4 relative'>
              <Image className='w-auto' style={{ objectFit: 'contain' }} src={item?.url} height={164} width={164} />
              {!isDisabled && (
                <CloseCircleOutlined
                  className='absolute text-white right-[2px] top-[2px] cursor-pointer z-50 font-bold'
                  onClick={() => removeImage(item)}
                  disabled={true}
                />
              )}
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default UploadImageDragger
