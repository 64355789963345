import { Button, Col, Image, Row, Typography } from 'antd'
import Constant from '../../../controller/Constant'

type Props = {
  renderFailApiMsg: string
}

const RenderFailView: React.FC<Props> = (props) => {
  const { renderFailApiMsg } = props

  return (
    <Col>
      <Row style={{ alignItems: 'center', marginTop: 35 }}>
        <img src={Constant.icon.bot} className='h-[44px] aspect-square mr-5' />
        <Typography
          style={{
            fontSize: 17,
            fontWeight: 'bold',
            color: 'red'
          }}
        >
          {renderFailApiMsg ? renderFailApiMsg : '画像生成が失敗しました。再度生成してください。'}
        </Typography>
      </Row>
    </Col>
  )
}

export default RenderFailView
