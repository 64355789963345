import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

type Prop = {
	images: string[]
}
export default function ImagesProcessing(prop: Prop) {
	const { images } = prop

	return (
		<div className='w-full flex justify-center content-center justify-items-center'>
			<div className='w-[660px] flex flex-wrap'>
				{images.map((item) => {
					return (<img className='w-[200px] m-[10px]' src={item} alt='media'/>)
				})}
				<div className='bg-gray-300 rounded-lg w-[200px] m-[10px] min-h-48 flex justify-center items-center'>
					<Spin spinning={true} indicator={<LoadingOutlined/>}></Spin>
				</div>
			</div>
		</div>
	)
}
