import { useState, useLayoutEffect } from 'react'
import { Flex, Modal } from 'antd'
import CommonAPIs from '../../../controller/API/CommonAPIs'
import Constant from '../../../controller/Constant'
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined'

const Introduction = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true)
  const [notShowAgain, setNotShowAgain] = useState<boolean>(false)

  useLayoutEffect(() => {
    //const isShowModal = localStorage.getItem('is_show_img2img_introduction')
    //setIsModalOpen(isShowModal ? true : false)
  }, [])

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const updateShowIntroduction = () => {
    if (notShowAgain) {
      CommonAPIs.checkShowIntroPopup(Constant.functionType.img2img)
        .then((isShow) => {
          if (isShow) {
            localStorage.setItem(Constant.localStorageKeys.isHideImg2ImgIntro, 'true')
          }
        })
        .catch((err) => {
          console.log('======= check show intro img2img: ', err)
        })
    }
    setIsModalOpen(false)
  }

  return (
    <Modal
      width={'42%'}
      className='ml-[12%]'
      centered
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer
      closeIcon={<CloseCircleOutlined className='text-black'/>}
      mousePosition={{ x: window.innerWidth / 2, y: window.innerHeight / 2 }}
    >
      <div className='w-full grid md:grid-cols-2 sm:grid-cols-1 gap-4 my-7'>
        <Flex className='flex-col w-full' justify='center'>
          <span className='bg-neutral-800 w-[60%] self-center text-white rounded-[25px] text-center p-2'>参考画像</span>
          <img
            className='mt-2 w-full self-center'
            src={require('../../../assets/img2img_introduction_2.png')}
            alt='img2img_introduction_2'
          />
        </Flex>
        <Flex className='flex-col w-full' justify='center'>
          <span className='bg-neutral-800 w-[60%] self-center text-white rounded-[25px] text-center p-2'>生成画像</span>
          <img
            className='mt-2 w-full self-center'
            src={require('../../../assets/img2img_introduction_1.png')}
            alt='img2img_introduction_1'
          />
        </Flex>
      </div>
      <span className='font-bold text-[16px]'>参考画像からデザイン提案</span>
      <p className='font-bold text-[13px]'>
        インスピレーション画像をアップロードすると、AIが自動的に画像を分析し、最適なデザインを提案します。
        <br />
        簡単にプロフェッショナルなデザインを生成できる便利なツールです。
      </p>
      <Flex className='flex-col mt-16' justify='center'>
        <Flex justify='center' className='font-medium text-sm'>
          <input type='checkbox' onChange={() => setNotShowAgain((prev) => !prev)} />
          <span className='ml-2 text-[13px] font-semibold'>次回からこのメッセージを表示しない</span>
        </Flex>
        <button
          className='w-[256px] h-[40px] text-[14px] bg-neutral-800 text-white rounded-md border-0 p-2 self-center mt-6 cursor-pointer'
          onClick={updateShowIntroduction}
        >
          閉じる
        </button>
      </Flex>
    </Modal>
  )
}

export default Introduction
