import { useContext, useState } from 'react'
import { Input, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import AuthAPIs from '../../../controller/API/AuthAPIs'
import { AppContext } from '../../../hooks/AppContext'

type Props = {
  email: string
}

const PasswordResetForm:React.FC<Props> = (prop: Props) => {
  const { email } = prop
  const { setIsSpinning, showErrorApiAlert, showProcessingAlert } = useContext(AppContext)
	const navigate = useNavigate()
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [validationErrors, setValidationErrors] = useState({
    password: '',
    passwordConfirmation: ''
  })

  const handleResetPassword = () => {
    setIsSpinning(true)
    let formInvalid = false
    setValidationErrors({
      password: '',
      passwordConfirmation: ''
    })
    
    // password
    if (!password) {
      setValidationErrors((prev) => {
        return {...prev, 'password': 'パスワードを入力してください。'}
      })
      formInvalid = true
    } else if (password.length < 6) {
      setValidationErrors((prev) => {
        return {...prev, 'password': 'パスワードは6文字以上入力してください。'}
      })
      formInvalid = true
    }
    
    // password confirmation
    if (!passwordConfirmation) {
      setValidationErrors((prev) => {
        return {...prev, passwordConfirmation: '確認用パスワードを入力してください。'}
      })
      formInvalid = true
    } else if (passwordConfirmation.length < 6) {
      setValidationErrors((prev) => {
        return {...prev, passwordConfirmation: '確認用パスワードは6文字以上入力してください。'}
      })
      formInvalid = true
    } else if (passwordConfirmation !== password) {
      setValidationErrors((prev) => {
        return {...prev, passwordConfirmation: 'パスワードと確認用パスワードが一致していません。'}
      })
      formInvalid = true
    }
    
    if (formInvalid) {
      setIsSpinning(false)
      return
    }
    
    // API call to reset password
    AuthAPIs.resetPassword(email, password, passwordConfirmation)
      .then((res) => {
        showProcessingAlert('パスワードを再設定に成功しました。')
        navigate('/login')
			})
      .catch((err) => {
        showErrorApiAlert(err)
      })
      .finally(() => setIsSpinning(false))
  }

	return (
		<>
			<span className='text-[32px] text-center'>パスワード再設定</span>
			<span className='mt-5 text-[16px]'>パスワード<span className='text-[#DC3545]'>*</span></span>
			<Input.Password
				placeholder='••••••••••••'
				type='password'
				onChange={(val: any) => setPassword(val?.target?.value)}
				className='w-full mt-1'
			/>
      <span className='text-[#DC3545] text-[14px] mt-1'>{ validationErrors.password }</span>
			<span className='mt-5 text-[16px]'>確認用パスワード<span className='text-[#DC3545]'>*</span></span>
			<Input.Password
				placeholder='••••••••••••'
				type='password'
				onChange={(val: any) => setPasswordConfirmation(val?.target?.value)}
				className='w-full mt-1'
			/>
      <span className='text-[#DC3545] text-[14px] mt-1'>{ validationErrors.passwordConfirmation }</span>
			<Button onClick={() => handleResetPassword() } className='bg-[#323539] text-white mt-8 text-[16px]'>
				設定する
			</Button>
			<div className='w-full flex justify-center mt-8'>
				<span 
          className='text-[12px] font-medium underline hover:cursor-pointer'
          onClick={() => navigate('/login')}
          >
          ログインはこちら
        </span>
			</div>
		</>
	)
}

export default PasswordResetForm
