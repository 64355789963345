import { Flex, Button } from 'antd'
import { useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import { v4 } from 'uuid'

import DownloadIcon from '../../../assets/ic-download.svg'
import CloseIcon from '../../../assets/icons/close-circle.svg'
import { removeBeforeUnload } from '../../../utils/utils'
type Props = {
  imageResults: string[]
  originalImage: string
}

const ImgResultView = (props: Props) => {
  const { imageResults, originalImage } = props
  const [previewImg, setPreviewImg] = useState<string>(imageResults[0])
  const [selectedPhotoKey, setSelectedPhotoKey] = useState(0)

  const chooseImage = (image: string, key: number) => {
    setPreviewImg(image)
    setSelectedPhotoKey(key)
  }

  const handleDownloadImage = async (imageUrl: string) => {
    try {
      let blob

      if (imageUrl.startsWith('data:')) {
        // Handle base64 image
        const base64Response = await fetch(imageUrl)
        blob = await base64Response.blob()
      } else {
        // Handle URL image
        const response = await fetch(imageUrl + '?not-from-cache-please')
        blob = await response.blob()
      }
      const fileName = v4()
      saveAs(blob, fileName)
    } catch (error) {
      console.log(error)
    }
  }

  const downloadAll = async () => {
    try {
      const downloadPromises = imageResults.map((url) => handleDownloadImage(url))
      await Promise.all(downloadPromises)
    } catch (error) {}
  }
  useEffect(() => {
    removeBeforeUnload()
  }, [])
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        className='container m-5 flex rounded-lg'
        style={{
          margin: 30,
          overflow: 'hidden',
          background: 'white',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          paddingBottom: 30,
          paddingInline: 30,
          paddingTop: 30,
          flexDirection: 'column'
        }}
      >
        <div className='w-full h-[75%] flex flex-col justify-center content-center relative'>
          <img
            className='w-[24px] absolute top-0 right-0 cursor-pointer hover:bg-[#d7d7d7] rounded'
            onClick={() => {
              removeBeforeUnload()
              window.location.reload()
            }}
            src={CloseIcon}
          />
          <img className='h-[450px] self-center' src={previewImg} alt='' />
          <Button className='w-32 self-center mt-5 text-white hidden bg-slate-900'>再生成する</Button>
        </div>
        <div className='w-full h-[25%] flex justify-center mt-7 flex-col'>
          <Flex className='flex-nowrap overflow-hidden hover:overflow-x-auto border-2' justify='center'>
            <div className='relative'>
              <img className='mx-3 self-start h-[150px]' src={originalImage} alt='' />
            </div>
            {imageResults.map((image, key) => {
              return (
                <div className='relative'>
                  <img
                    className='cursor-pointer hover:bg-[#d7d7d7] absolute w-[25px] h-[25px] top-[5px] right-[20px] p-[3px] rounded-full'
                    src={DownloadIcon}
                    onClick={() => handleDownloadImage(image)}
                  />
                  <img
                    className={`mx-3 self-start h-[150px] ${
                      selectedPhotoKey === key ? 'border-cyan-500 border-solid' : ''
                    }`}
                    src={image}
                    onClick={() => chooseImage(image, key)}
                    alt=''
                    key={key}
                  />
                </div>
              )
            })}
          </Flex>
          <Flex justify='center'>
            <Button
              type='primary'
              className='w-[256px] h-[40px] size-[16px] mt-5'
              style={{ background: '#323539', borderRadius: 9 }}
              onClick={downloadAll}
            >
              画像を一括保存
            </Button>
          </Flex>
        </div>
      </div>
    </div>
  )
}

export default ImgResultView
