import axios from 'axios'
import BaseAPIs from './BaseAPI'

export default class AuthAPIs extends BaseAPIs {
	static endpoints = {
		emailVerificationCodeRequest: this.baseURL + '/ai-img/send-verify-email',
		apiVerifyCode: this.baseURL + '/ai-img/verify-code-forgot-password',
		apiResetPassword: this.baseURL + '/ai-img/reset-password',
	}

	static async sendEmailVerificationCodeRequest(email: string) {
		try {
			const formData = new FormData()
			formData.append('email', email)

			let response = await axios.post(
				this.endpoints.emailVerificationCodeRequest, 
				formData, 
				{
					headers: this.headers
				}
			)

			return Promise.resolve(response.data?.data)
		} catch (error) {
			return Promise.reject(error)
		}
	}
	
	static async sendApiVerifyCode(email: string, code: string) {
		try {
			const formData = new FormData()
			formData.append('email', email)
			formData.append('code', code)

			let response = await axios.post(
				this.endpoints.apiVerifyCode, 
				formData, 
				{
					headers: this.headers
				}
			)

			return Promise.resolve(response.data?.data)
		} catch (error) {
			return Promise.reject(error)
		}
	}
	
	// function reset password
	static async resetPassword(email: string, password: string, passwordConfirmation: string) {
		try {
			const formData = new FormData()
			formData.append('email', email)
			formData.append('password', password)
			formData.append('password_confirmation', passwordConfirmation)

			let response = await axios.post(
				this.endpoints.apiResetPassword, 
				formData, 
				{
					headers: this.headers
				}
			)

			return Promise.resolve(response.data?.data)
		} catch (error) {
			return Promise.reject(error)
		}
	}
}