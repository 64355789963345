import { Flex, Typography, Button, Space, Image, Row, Upload, ConfigProvider } from 'antd'
import Constant from '../../controller/Constant'
import { useThreadStore } from '../../controller/store'
import SystemPromptOptions from './components/SystemPromptOptions'
import { useContext, useEffect, useState } from 'react'
import UploadImg2ImgDragger from './components/UploadImg2ImgDragger'
import useNewThreadImg2Img from '../../hooks/useNewThreadImg2Img'
import { GenerateImg2ImgType, ImageType } from '../../types'
import { FolderOutlined } from '@ant-design/icons'
import RenderFullFlowItem from './RenderFullFlowItem'
import EditSystemPromptView from './components/EditSystemPromptView'
import ResultPhotosView from './components/ResultPhotosView'
import RenderFailView from './components/RenderFailView'
import RerenderImg2ImgView from './components/RerenderImg2ImgView'
import SelectCheckPointGuideView from './components/SelectCheckPointGuideView'
import { AppContext } from '../../hooks/AppContext'

type Props = {
  jobRunning: boolean
  setJobRunning: (value: boolean) => void
}

const NewThreadStepsItem: React.FC<Props> = (props) => {
  const { jobRunning, setJobRunning } = props
  const { windowWidth, isMobile } = useContext(AppContext)
  const { checkpointImg2ImgSelected, threadSelectedID, checkpointProfile, newThreadID, countNewThread } =
    useThreadStore()

  const {
    checkIsDisabled,
    handleUploadChangeNewThread,
    beforeUpload,
    removeImageNewThread,
    fileListBase64NewThread,
    handleRequestImg2Img,
    isRequestedImg2Img,
    renderImg2ImgArray,
    checkConditionNextStep,
    requestImg2ImgSuccess,
    onSelectEditSystemPrompt,
    mediaProcessingJobID,
    generatedImageList,
    handleRequestReGenerate,
    isRenderFail,
    renderFailApiMsg,
    onClickRerenderFullFlow,
    setIsBlockSelectPrompt,
    isBlockSelectPrompt,
    getMediasProcessing,
    isStopLoop,
    fileListBase64,
    isRenderedFirst,
    handleRequestNewThread,
    systemPromptType,
    setSystemPromptType,
    handleUploadChange,
    checkUploadMultiImg,
    handleResetDt,
    removeImage
  } = useNewThreadImg2Img(jobRunning, setJobRunning)

  useEffect(() => {
    if (jobRunning) {
      setIsBlockSelectPrompt(true)
    }
  }, [jobRunning])

  useEffect(() => {
    if (mediaProcessingJobID) {
      setJobRunning(true)
      const timerId = setInterval(() => {
        getMediasProcessing(mediaProcessingJobID)
      }, 5000)

      if (isStopLoop) {
        clearInterval(timerId)
      }

      return () => {
        clearInterval(timerId)
      }
    }
  }, [mediaProcessingJobID, isStopLoop])

  useEffect(() => {
    handleResetDt()
  }, [countNewThread])

  return (
    <Flex vertical>
      <Flex
        vertical
        style={{
          width: windowWidth * (605 / 1440)
        }}
      >
        <SelectCheckPointGuideView />
        {checkpointImg2ImgSelected.new_checkpoint && (
          <SystemPromptOptions
            systemPromptType={systemPromptType}
            handleRequestImg2Img={handleRequestNewThread}
            isBlockSelectPrompt={isBlockSelectPrompt}
          />
        )}

        {systemPromptType ? (
          <>
            <Flex align='center' className='mt-3'>
              <img src={Constant.icon.bot} className='h-[44px] aspect-square mr-5' />
              <Typography.Text strong className='text-[20px]'>
                画像のアップロードをしてください
              </Typography.Text>
            </Flex>
            <Typography.Text className='ml-14 mt-5'>・画像データ10枚までアップロード出来ます。</Typography.Text>
            {isRequestedImg2Img ? (
              <>
                <Space className=' ml-[65px] flex-wrap mt-8'>
                  {fileListBase64NewThread?.map((item: ImageType) => (
                    <Image
                      src={item?.url}
                      height={(windowWidth * (checkUploadMultiImg() ? 150 : 350)) / 1440}
                      className='aspect-square object-cover rounded-lg'
                    />
                  ))}
                </Space>
                <EditSystemPromptView
                  systemPromptType={systemPromptType}
                  onSelectEditSystemPrompt={onSelectEditSystemPrompt}
                  mediaProcessingJobID={mediaProcessingJobID}
                />
                {generatedImageList?.map((item: GenerateImg2ImgType, index: number) => (
                  <ResultPhotosView
                    key={item?.id}
                    mediaProcessingJobID={mediaProcessingJobID}
                    item={item}
                    index={index + 1}
                    onReGenerateImg={handleRequestReGenerate}
                    checkIsDisabled={checkIsDisabled}
                  />
                ))}
                {mediaProcessingJobID ? (
                  <ResultPhotosView
                    isProcessing={true}
                    index={generatedImageList?.length + 1}
                    mediaProcessingJobID={mediaProcessingJobID}
                    onReGenerateImg={handleRequestReGenerate}
                    checkIsDisabled={checkIsDisabled}
                  />
                ) : (
                  <div />
                )}
                {isRenderFail && <RenderFailView renderFailApiMsg={renderFailApiMsg} />}
                {!jobRunning && renderImg2ImgArray?.length <= 0 && (
                  <RerenderImg2ImgView jobRunning={jobRunning} onClickRerenderFullFlow={onClickRerenderFullFlow} />
                )}
              </>
            ) : (
              <Flex
                vertical
                className='ml-14'
                style={{
                  maxWidth: 434,
                  width: (434 * windowWidth) / 1440
                }}
              >
                <UploadImg2ImgDragger
                  isDisabled={checkIsDisabled()}
                  handleUploadChange={handleUploadChangeNewThread}
                  beforeUpload={beforeUpload}
                  removeImage={removeImageNewThread}
                  fileListBase64={fileListBase64NewThread}
                />
                {fileListBase64NewThread?.length > 0 && (
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimaryHover: 'white'
                        }
                      }
                    }}
                  >
                    <Button
                      onClick={() => handleRequestImg2Img(systemPromptType, fileListBase64NewThread)}
                      className='bg-[#323539] text-white font-bold  h-10 self-center'
                      style={{ width: windowWidth * (256 / 1440), maxWidth: 256 }}
                    >
                      アップロードする
                    </Button>
                  </ConfigProvider>
                )}
              </Flex>
            )}
          </>
        ) : (
          <div />
        )}
        {requestImg2ImgSuccess && renderImg2ImgArray?.length > 0 && <Row className='h-[1px] bg-[#D3D3D3] my-8' />}
      </Flex>

      {/* flow render cũ */}

      {renderImg2ImgArray?.map((item, index) => (
        <RenderFullFlowItem
          key={index}
          fileListBase64={item?.images}
          isItemLast={index + 1 == renderImg2ImgArray?.length}
          checkpointProfile={checkpointProfile}
          setJobRunning={setJobRunning}
          jobRunning={jobRunning}
          threadSelectedID={newThreadID}
        />
      ))}

      {requestImg2ImgSuccess && (
        <Flex vertical justify='center' align='center'>
          <Row className='h-[1px] bg-[#D3D3D3] mt-8 mx-5 ' />
          <Flex
            className='mt-7 bg-white w-[80%] text-center content-center rounded-lg pr-5 pl-5 pt-6 pb-6 self-center'
            justify='space-between'
            align='center'
            style={{ backgroundColor: jobRunning ? '#C4C4C4' : 'white' }}
          >
            <Typography className='w-8' />
            <Flex vertical justify='center' align='center'>
              <Space className='mb-4 flex flex-wrap'>
                {fileListBase64?.map((item: ImageType) => (
                  <Flex key={item?.uid} className='ml-1 mr-1 mb-4 relative'>
                    <Image src={item?.url} width='auto' height={100} />
                    <img
                      src={Constant.icon.closeTransparent}
                      className='w-4 aspect-square absolute text-white right-[2px] top-[2px] cursor-pointer z-50 object-cover'
                      onClick={() => removeImage(item)}
                    />
                  </Flex>
                ))}
              </Space>
              <Upload
                listType='picture'
                onChange={handleUploadChange}
                name='avatar'
                className='avatar-uploader '
                showUploadList={false}
                multiple
                beforeUpload={beforeUpload}
                openFileDialogOnClick={jobRunning ? false : true}
                disabled={jobRunning ? true : false}
              >
                <Flex className='cursor-pointer'>
                  <FolderOutlined className='text-[20px] mr-2' style={{ color: jobRunning ? 'white' : 'black' }} />
                  <Typography
                    className='text-[18px] overflow-hidden whitespace-nowrap text-ellipsis'
                    style={{ color: jobRunning ? 'white' : 'black' }}
                  >
                    画像をアップロード
                  </Typography>
                </Flex>
              </Upload>
            </Flex>
            <img
              className='text-[25px] ml-2 w-[25px] aspect-square cursor-pointer'
              src={require('../../assets/up.png')}
              onClick={() => (jobRunning ? undefined : checkConditionNextStep())}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default NewThreadStepsItem
