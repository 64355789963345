import { useState } from 'react'
import { Flex } from 'antd'
import logo from '../../../assets/logo_big.png'
import EmailEntryForm from './EmailEntryForm'
import VerificationCodeEntryForm from './VerificationCodeEntryForm'
import PasswordResetForm from './PasswordResetForm'

const ForgotPasswordScreen = () => {
	const [email, setEmail] = useState<string>('')
	const [step, setStep] = useState(1)

	return (
		<Flex vertical align='center' className='bg-white h-[100vh]'>
			<img className='mt-10 mb-3' width={120} src={logo} alt='' />
			<div className='md:w-[40%] xs:w-[80%] bg-[#F6F6F6] flex justify-center content-center rounded-lg mt-6 mb-10'>
				<Flex vertical className='max-w-[363px] w-[80%] py-[80px]'>
					{ step === 1 && 
						<EmailEntryForm 
							setStep={() => setStep(2)} 
							email={email} 
							setEmail={(email: string) => {setEmail(email)}} 
							/> 
					}
					{ step === 2 && 
						<VerificationCodeEntryForm
							email={email}
							setStep={() => setStep(3)} 
						/> 
					}
					{ step === 3 && <PasswordResetForm email={email} /> }
				</Flex>
			</div>
		</Flex>
	)
}

export default ForgotPasswordScreen
