import React, { useContext, useEffect, useState } from 'react'
import { Input, Flex, Button } from 'antd'
import '../../controller/styles.css'
import logo from '../../assets/logo_big.png'
import { useNavigate } from 'react-router-dom'
import CommonAPIs from '../../controller/API/CommonAPIs'
import { AppContext } from '../../hooks/AppContext'
import Constant from '../../controller/Constant'

const LoginScreen = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { setIsSpinning, showErrorApiAlert, setMenuSelected } = useContext(AppContext)

  const showHomeScreen = () => {
    setIsSpinning(true)
    CommonAPIs.login(email, password)
      .then((res) => {
        navigate('/')
        setMenuSelected(0)
        localStorage.setItem('token', res.data?.access_token)
        localStorage.setItem('email', email)
        localStorage.setItem(
          Constant.localStorageKeys.isHideChangeFabricIntro,
          res.data?.show_change_fabric == 1 ? 'false' : 'true'
        )
        localStorage.setItem(
          Constant.localStorageKeys.isHideImg2ImgIntro,
          res.data?.show_img2img == 1 ? 'false' : 'true'
        )
        localStorage.setItem(
          Constant.localStorageKeys.isHideLoraIntro,
          res.data?.show_train_lora == 1 ? 'false' : 'true'
        )
        localStorage.setItem(
          Constant.localStorageKeys.isHideTxt2ImgIntro,
          res.data?.show_txt2img == 1 ? 'false' : 'true'
        )
      })
      .catch((err) => showErrorApiAlert(err))
      .finally(() => setIsSpinning(false))
  }

  useEffect(() => {
    const access_token = localStorage.getItem('token')
    if (access_token) {
      navigate('/')
      setMenuSelected(0)
    }
  }, [])

  return (
    <Flex vertical align='center' className='bg-white h-[100vh]'>
      <img className='mt-10 mb-3' width={120} src={logo} alt='logo' />
      <div className='md:w-[40%] xs:w-[80%] max-w-[537px] min-w-[320px] bg-[#F6F6F6] flex justify-center content-center rounded-lg mt-6 mb-10'>
        <Flex vertical className='max-w-[360px] w-[80%] my-16'>
          <p>メールアドレス</p>
          <Input
            placeholder='メールアドレス'
            onChange={(text: any) => setEmail(text?.target?.value)}
            className='w-full'
          />
          <p>パスワード</p>
          <Input.Password
            placeholder='パスワード'
            type='password'
            onChange={(text: any) => setPassword(text?.target?.value)}
            className='w-full'
          />
          <Button onClick={showHomeScreen} className='bg-[#323539] text-white mt-10 text-[16px]'>
            ログイン
          </Button>
          <span className='text-center mt-5 text-[12px]'>
            パスワードを忘れた方は
            <span 
              onClick={() => navigate('/forgot-password')}
              className='underline text-[#212529] hover:cursor-pointer'>こちら</span>
            </span>
          <hr className='w-full mt-5 bg-black' />
          <Button onClick={() => navigate('/register')} className='bg-white text-[#323539] text-[16px] border-[#323539] mt-10 w-[70%] self-center'>新規会員登録はこちら</Button>
        </Flex>
      </div>
      <div className='w-full h-10 bg-black flex justify-center content-center fixed bottom-0 left-0 right-0'>
        <a href='https://nft-snap-dev.s3.ap-northeast-1.amazonaws.com/jewelry_pdf/%E3%82%AF%E3%83%AA%E3%83%AA%E3%83%B3+%E7%89%B9%E5%AE%9A%E5%95%86%E5%8F%96%E5%BC%95%E6%B3%95%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%8F%E8%A1%A8%E8%A8%98.pdf' className='text-[14px] text-white font-bold self-center' target='_blank' rel='noreferrer'>特定商取引法に基づく表記</a>
      </div>
    </Flex>
  )
}

export default LoginScreen
