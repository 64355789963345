import { Image, Row, Typography, Flex } from 'antd'
import moment from 'moment'
import { useContext, useLayoutEffect, useRef, useState } from 'react'
import { AppContext } from '../../../hooks/AppContext'
import { RenderHistoryItemType } from '../../../types/media_type'
import PreviewImagesModal from '../../Img2Img/components/PreviewImagesModal'
import { useNavigate } from 'react-router-dom'

type Props = {
  item: RenderHistoryItemType
  isLoraProcessing?: boolean
}

const DescriptionItem: React.FC<Props> = (props) => {
  const { item, isLoraProcessing } = props
  const { isMobile, showCopyCompleted } = useContext(AppContext)
  const parentRef: any = useRef()
  const [heightParent, setHeightParent] = useState<number>(0)
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false)
  const [imagesPreview, setImagesPreview] = useState<{ url: string }[]>([])
  const navigate = useNavigate()

  const onClickTemplate = () => {
    navigate('/create-product-content?step=0', { state: { step: 0, template: item?.template } })
  }

  const copyProductContent = () => {
    navigator.clipboard.writeText(props.item?.content)
    showCopyCompleted()
  }

  const fontMobileStyle = {
    fontSize: 12,
    marginRight: 5,
    height: '100%',
    cursor: 'pointer'
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      setHeightParent(parentRef.current?.offsetHeight)
    }

    const height = parentRef?.current?.offsetHeight
    setHeightParent(height)

    setTimeout(() => {
      const newHeight = parentRef?.current?.offsetHeight
      if (height !== newHeight) {
        setHeightParent(newHeight)
      }
    }, 2000)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Row className='min-h-9 items-center text-center w-full h-[90px]'>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography.Paragraph style={isMobile ? fontMobileStyle : { textAlign: 'left' }}>
            {item.user?.name ?? item?.user?.email}
          </Typography.Paragraph>
        </Flex>
        <Flex className='flex-[0.5]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Flex className=''>
            <Image
              preview={false}
              onClick={() => {
                setImagesPreview([{ url: item?.image_url }])
                setIsShowPreview(true)
              }}
              src={item?.image_url}
              height={60}
              style={{ width: 60, objectFit: 'cover' }}
            />
          </Flex>
        </Flex>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography style={isMobile ? fontMobileStyle : { textAlign: 'left' }}>
            {moment(item?.created_at).format('YYYY/MM/DD HH:mm')}
          </Typography>
        </Flex>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography
            onClick={onClickTemplate}
            style={isMobile ? fontMobileStyle : { textAlign: 'left', cursor: 'pointer' }}
          >
            {item?.template?.title}
          </Typography>
        </Flex>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography
            style={isMobile ? fontMobileStyle : { height: 60, overflowY: 'auto' }}
            className='scroll-hover flex-[1.5] whitespace-pre-line text-start pt-3 mb-3'
          >
            {item?.content}
          </Typography>
        </Flex>
        <Flex className='flex-[1]' style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
          <button
            style={{
              height: 27,
              width: 62,
              padding: 0,
              background: 'white',
              color: 'black',
              border: '1px solid black'
            }}
            onClick={copyProductContent}
          >
            コピー
          </button>
        </Flex>
      </Row>
      <Row className='h-[1px] bg-[#5AAAFF]' />
      <PreviewImagesModal
        imageList={imagesPreview ?? []}
        indexPreview={0}
        isPreview={isShowPreview}
        setIsPreview={setIsShowPreview}
      />
    </>
  )
}

export default DescriptionItem
