import { useCallback, useContext, useState } from 'react'
import { message } from 'antd'
import { AppContext } from './AppContext'
import { CheckpointProfileType, NewCheckpointType } from '../types'
import CommonAPIs from '../controller/API/CommonAPIs'
import { ImageType } from '../types/media_type'
import { UploadChangeParam } from 'antd/es/upload'
import { RcFile } from 'antd/lib/upload'
import Img2ImgAPIs from '../controller/API/Img2ImgAPIs'
import { PromptThreadType, ThreadImg2ImgHistoryType } from '../types/thread'
import { useThreadStore } from '../controller/store'
import Constant from '../controller/Constant'

const MAX_UPLOAD_PICTURE = 10

type RenderImg2ImgArray = {
  images: ImageType[]
}

const useImg2Img = () => {
  const { showErrorApiAlert, setIsSpinning } = useContext(AppContext)
  const {
    setThreadList,
    setCheckpointImg2ImgSelected,
    checkpointImg2ImgSelected,
    checkpointProfile,
    setCheckpointProfile
  } = useThreadStore()
  const [checkPointSelected, setCheckPointSelected] = useState<NewCheckpointType>({
    new_checkpoint: '',
    name: '',
    first_image: ''
  })
  const [checkPoints, setCheckPoints] = useState<NewCheckpointType[]>([])
  const [checkpointProfileEdited, setCheckpointProfileEdited] = useState<string>('')
  const [isShowProfileModal, setIsShowProfileModal] = useState(false)
  const [renderImg2ImgArray, setRenderImg2ImgArray] = useState<RenderImg2ImgArray[]>([])
  const [fileListBase64, setFileListBase64] = useState<ImageType[]>([])
  const [threadHistory, setThreadHistory] = useState<ThreadImg2ImgHistoryType[]>([])
  const [isShowIntroduction, setIsShowIntroduction] = useState<boolean>(false)

  const checkShowIntroduction = () => {
    const isHideLoraIntro = localStorage.getItem(Constant.localStorageKeys.isHideImg2ImgIntro)
    setIsShowIntroduction(isHideLoraIntro === 'true' ? false : true)
    localStorage.setItem(Constant.localStorageKeys.isHideImg2ImgIntro, 'true')
  }

  const handleData = (index: number): void => {
    setCheckPointSelected(checkPoints[index])
  }

  const getNewCheckpoint = (): void => {
    CommonAPIs.getNewCheckpointList()
      .then((res) => {
        setCheckPoints(res.data)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const handleSelectCheckpoint = (checkpoint: any) => {
    setCheckPointSelected(checkpoint)
    setCheckpointImg2ImgSelected(checkpoint)
  }

  const handleUploadChange = useCallback(
    (info: UploadChangeParam<any>) => {
      const isImage = info?.file.type.startsWith('image/')

      if (!isImage) {
        return
      }

      const newFile: RcFile = info.file.originFileObj

      if (!fileListBase64.some((file) => file?.uid == newFile?.uid) && fileListBase64?.length < MAX_UPLOAD_PICTURE) {
        const reader = new FileReader()
        reader.readAsDataURL(newFile)
        reader.onload = (e: any) => {
          setFileListBase64((prev: any) => {
            if (prev?.length >= MAX_UPLOAD_PICTURE) {
              return prev
            }
            return [
              ...prev,
              {
                uid: newFile?.uid,
                url: e.target.result
              }
            ]
          })
        }
      }
    },
    [fileListBase64]
  )

  const beforeUpload = (file: RcFile) => {
    const isImage = file.type.startsWith('image/')
    if (!isImage) {
      message.error('アップロードは画像ファイルではありません')
      return
    }
    if (fileListBase64?.length >= MAX_UPLOAD_PICTURE) {
      message.error('画像を10枚以下でアップロードてください。')
      return
    }
  }

  const getCheckpointProfile = () => {
    CommonAPIs.getCheckpointProfile(checkpointImg2ImgSelected?.new_checkpoint)
      .then((res: CheckpointProfileType) => {
        console.log('checkpoint-profile: ', res)
        setCheckpointProfile(res)
        setCheckpointProfileEdited(res.profile_jp)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const updateCheckpointProfile = () => {
    setIsSpinning(true)
    setIsShowProfileModal(false)

    CommonAPIs.updateCheckpointProfile(checkPointSelected?.new_checkpoint, checkpointProfileEdited)
      .then((res) => {
        console.log('res:', res)
        const newDt: any = {
          ...checkpointProfile,
          profile_jp: checkpointProfileEdited
        }
        setCheckpointProfile(newDt)
        setCheckpointProfileEdited(checkpointProfileEdited)
      })
      .catch((err) => showErrorApiAlert(err))
      .finally(() => setIsSpinning(false))
  }

  const checkConditionNextStep = () => {
    if (fileListBase64?.length <= 0) {
      message.error('画像データをアップロードしてください。')
      return
    }

    if (!checkpointProfile?.profile_jp || checkpointProfile?.profile_jp == '') {
      message.error('プロフィールを入力してください。')
      return
    }

    if (fileListBase64?.length > 0) {
      const newArray = [
        ...renderImg2ImgArray,
        {
          images: fileListBase64
        }
      ]
      setRenderImg2ImgArray(newArray)
      setFileListBase64([])
    }
  }

  const handleCancelSaveProfile = () => {
    setIsShowProfileModal(false)
    setCheckpointProfileEdited(checkpointProfile?.profile_jp ?? '')
  }

  const getThreadContentHistory = (threadID: number) => {
    Img2ImgAPIs.getThreadImg2ImgHistory(threadID)
      .then((res: ThreadImg2ImgHistoryType[]) => {
        let reverseDt: ThreadImg2ImgHistoryType[] = res.reverse()

        // reverseDt?.map((item: ThreadImg2ImgHistoryType) => {
        //   item?.prompts?.filter((items: PromptThreadType) => {
        //     if (items?.images?.length > 0) {
        //       return items
        //     }
        //   })
        // })

        console.log('threadHistory-reverse:', reverseDt)

        setThreadHistory(reverseDt)
        console.log('ppppppoooooo: ', res)
        setCheckpointImg2ImgSelected({
          name: reverseDt[reverseDt?.length - 1]?.checkpoint ?? '',
          new_checkpoint: reverseDt[reverseDt?.length - 1]?.checkpoint,
          first_image: reverseDt[reverseDt?.length - 1]?.thumbnail
        })
        setCheckpointProfile(reverseDt[reverseDt?.length - 1]?.checkpoint_profile)
      })
      .catch((err) => console.log('err', err))
  }

  const getThreadList = () => {
    Img2ImgAPIs.getThreadList()
      .then((res) => {
        console.log('threadList:', res)
        setThreadList(res)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const removeImage = (item: ImageType) => {
    const newDt = fileListBase64?.filter((items) => items?.uid != item?.uid)
    setFileListBase64(newDt)
  }

  return {
    getNewCheckpoint,
    checkPoints,
    checkPointSelected,
    handleData,
    handleSelectCheckpoint,
    getCheckpointProfile,
    checkpointProfile,
    setCheckpointProfile,
    checkpointProfileEdited,
    setCheckpointProfileEdited,
    updateCheckpointProfile,
    isShowProfileModal,
    setIsShowProfileModal,
    handleCancelSaveProfile,
    renderImg2ImgArray,
    setRenderImg2ImgArray,
    handleUploadChange,
    beforeUpload,
    fileListBase64,
    checkConditionNextStep,
    getThreadContentHistory,
    threadHistory,
    getThreadList,
    setThreadHistory,
    removeImage,
    checkShowIntroduction,
    isShowIntroduction
  }
}

export default useImg2Img
