import { Flex, message } from 'antd'
import React, { useState, memo, useCallback } from 'react'
import { Upload, UploadFile } from 'antd/lib'
import { UploadChangeParam } from 'antd/es/upload'
import { RcFile } from 'antd/lib/upload'
import ImgCrop from 'antd-img-crop'
import { ImageType } from '../../../types'

type Props = {
  isDisabled?: boolean
  onUploadImg: (info: ImageType) => void
  onCancelCrop: () => void
  beforeUpload: ((file: RcFile, FileList: RcFile[]) => void) | undefined
}

const SubUploadImg2Img: React.FC<Props> = (props) => {
  const { isDisabled = false, onUploadImg, beforeUpload, onCancelCrop } = props
  const [selectImg, setSelectImg] = useState(true)
  const [fabricInvalid, setFabricInvalid] = useState(false)

  const handleCrop = useCallback(
    (info: any) => {
      console.log('start crop');
      console.log(selectImg, fabricInvalid);
      
      if (selectImg && !fabricInvalid) {
        const croppedImage = info.file.originFileObj
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.src = URL.createObjectURL(croppedImage)
        image.onload = () => {
          canvas.width = 512
          canvas.height = 512
          ctx?.drawImage(image, 0, 0, 512, 512)
          const dataUrl = canvas.toDataURL('image/jpeg')
          console.log('crop img::::', dataUrl);
          setTimeout(() => {
            onUploadImg({ uid: croppedImage?.uid, url: dataUrl })
          }, 2000)
        }
      }
    },
    [selectImg, fabricInvalid]
  )

  const handleBeforeCrop = (file: any) => {
    setFabricInvalid(false)
    const image = new Image()
    image.src = URL.createObjectURL(file)
    image.onload = () => {
      if (image.width < 512 || image.height < 512) {
        setFabricInvalid(true)
        message.error('512×512以上の画像サイズが推奨です。')
      }
    }
  }

  const cancelCrop = useCallback(() => {
    onCancelCrop()
    setSelectImg(false)
  }, [])

  const uploadButton = (
    <Flex vertical justify='center' align='center'>
      <p className='flex text-[#6B7280] mt-2 whitespace-pre-line text-center'>
        {`ここに画像をドロップ\n-または-\nクリックしてアップロード`}
      </p>
    </Flex>
  )

  return (
    <Flex className='w-full' align='center' justify='center' vertical style={{ maxWidth: window.innerWidth }}>
      <ImgCrop
        showGrid
        showReset
        onModalCancel={cancelCrop}
        beforeCrop={handleBeforeCrop}
        onModalOk={() => setSelectImg(true)}
      >
        <Upload
          listType='picture'
          onChange={handleCrop}
          name='avatar'
          className='avatar-uploader'
          showUploadList={false}
          disabled={isDisabled}
          beforeUpload={beforeUpload}
        >
          <Flex
            className='p-3 cursor-pointer border-dashed border-[#6B7280] rounded-[10px] border-[0.5px]'
            vertical
            style={{
              backgroundColor: isDisabled ? '#f6f6f6' : 'white'
            }}
            justify='center'
            align='center'
          >
            <Flex justify='center' align='center'>
              {uploadButton}
            </Flex>
          </Flex>
        </Upload>
      </ImgCrop>
      {fabricInvalid && (<span className='text-red-600'>512×512以上の画像サイズが推奨です。</span>)}
    </Flex>
  )
}

export default memo(SubUploadImg2Img)
