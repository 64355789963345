import { useContext, useState } from 'react'
import { AppContext } from './AppContext'
import CommonAPIs from '../controller/API/CommonAPIs'

import moment from 'moment'
import { RenderHistoryItemType } from '../types/media_type'

const useRenderHistory = () => {
  const { isSpinning, setIsSpinning, showErrorApiAlert } = useContext(AppContext)
  const [loraList, setLoraList] = useState<RenderHistoryItemType[]>([])
  const [imgList, setImgList] = useState<RenderHistoryItemType[]>([])
  const [schedule, setSchedule] = useState<{
    startTime: Date | string
    endTime: Date | string
  }>({
    startTime: moment().subtract(6, 'days').toDate(),
    endTime: new Date()
  })

  const [loraProcessingList, setLoraProcessingList] = useState<RenderHistoryItemType[]>([])
  const [renderImgsList, setRenderImgsList] = useState<RenderHistoryItemType[]>([])
  const [descriptionList, setDescriptionList] = useState([])
  const [renderDescriptionList, setRenderDescriptionList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [isLoraHistory, setIsLoraHistory] = useState(false)
  const [tab, setTab] = useState(1)

  const getRenderHistoryList = () => {
    setIsSpinning(true)
    CommonAPIs.getRenderHistory(schedule.startTime, schedule.endTime, currentPage)
      .then((res) => {
        console.log('history:', res)
        setImgList(res?.data)
        console.log('++++++++ Render AI images: ', res?.meta.last_page)
        setTotalPages(res?.meta.last_page)
      })
      .finally(() => setIsSpinning(false))
  }

  const getLorasTrained = () => {
    setIsSpinning(true)
    CommonAPIs.getLoraTrainedHistory(schedule.startTime, schedule.endTime, currentPage)
      .then((res) => {
        console.log('LORA history:', res)
        setLoraList(res?.data)
        console.log('++++++++ Lora: ', res?.meta.last_page)
        setTotalPages(res?.meta.last_page)
      })
      .finally(() => setIsSpinning(false))
  }

  const getImgProcessing = () => {
    const loraProcessingStorageAmount = localStorage.getItem('imgProcessingAmount') ?? 0
    CommonAPIs.getRenderImgsProcessing()
      .then((res) => {
        console.log('processing:', res)
        if (loraProcessingStorageAmount && Number(loraProcessingStorageAmount) > res?.length) {
          getRenderHistoryList()
        }
        setRenderImgsList(res)
        localStorage.setItem('imgProcessingAmount', res?.length)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const getDescription = () => {
    setIsSpinning(true)
    CommonAPIs.getDescriptionHistory(schedule.startTime, schedule.endTime, currentPage)
      .then((res) => {
        setDescriptionList(res?.data)
        // console.log('++++++++ Desciption: ', res?.meta.last_page)
        setTotalPages(res?.meta.last_page)
      })
      .finally(() => setIsSpinning(false))
  }

  const getRenderDescription = () => {
    const loraProcessingStorageAmount = localStorage.getItem('loraProcessingAmount') ?? 0
    CommonAPIs.getRenderDescriptionHistory()
      .then((res) => {
        console.log('Loras processing:', res)
        if (loraProcessingStorageAmount && Number(loraProcessingStorageAmount) > res?.length) {
          getDescription()
        }
        setRenderDescriptionList(res?.data ?? [])
        localStorage.setItem('loraProcessingAmount', res?.length)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const getLoraProcessing = () => {
    console.log('====== CALL API GET LORA PROCESSING ======')
    const loraProcessingStorageAmount = localStorage.getItem('loraProcessingAmount') ?? 0
    CommonAPIs.getLoraProcessing()
      .then((res) => {
        console.log('Loras processing:', res)
        if (loraProcessingStorageAmount && Number(loraProcessingStorageAmount) > res?.length) {
          getLorasTrained()
        }
        setLoraProcessingList(res?.data ?? [])
        localStorage.setItem('loraProcessingAmount', res?.length)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const onChangePage = (page: any) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  const handleFilter = () => {
    switch (tab) {
      case 1:
        getImgProcessing()
        getRenderHistoryList()
        break
      case 2:
        getLoraProcessing()
        getLorasTrained()
        break
      case 3:
        getDescription()
        break
      default:
        break
    }
  }

  return {
    getRenderHistoryList,
    getLoraProcessing,
    getImgProcessing,
    handleFilter,
    schedule,
    setSchedule,
    loraProcessingList,
    isSpinning,
    loraList,
    totalPages,
    onChangePage,
    currentPage,
    setLoraProcessingList,
    isLoraHistory,
    setIsLoraHistory,
    imgList,
    getLorasTrained,
    renderImgsList,
    setRenderImgsList,
    tab,
    setTab,
    getDescription,
    descriptionList,
    getRenderDescription,
    renderDescriptionList,
    setCurrentPage
  }
}

export default useRenderHistory
