import { useContext, useState } from 'react'
import { Input, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../../hooks/AppContext'
import AuthAPIs from '../../../controller/API/AuthAPIs'

type Props = {
  setStep: () => void
  email: string
  setEmail: (email: string) => void
}

const EmailEntryForm: React.FC<Props> = (prop: Props) => {
  const { setStep, email, setEmail  } = prop
  const { setIsSpinning, showErrorApiAlert } = useContext(AppContext)
  const navigate = useNavigate()
  const [validationErrors, setValidationErrors] = useState({
    email: ''
  })

  const handleCodeRequest = () => {
    setIsSpinning(true)
    
    let formInvalid = false
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setValidationErrors((prev) => {
        return {...prev, 'email': 'メールアドレスを入力してください。'}
      })
      formInvalid = true
    }else if (!emailRegex.test(email)) {
      setValidationErrors((prev) => {
        return {...prev, 'email': 'メールアドレスの形式が間違っています。'}
      })
      formInvalid = true
    }
    
    if (formInvalid) {
      setIsSpinning(false)
      return
    }

    AuthAPIs.sendEmailVerificationCodeRequest(email)
      .then((res) => {
				setStep()
			})
      .catch((err) => {
        if (err.response.data.message == 'このメールアドレースが存在しません。') {
          setValidationErrors((prev) => {
            return {...prev, 'email': 'このメールアドレースが存在しません。'}
          })
          return
        }

        showErrorApiAlert(err)
      })
      .finally(() => setIsSpinning(false))
  }

  return (
    <>
      <span className='text-[32px] text-center'>パスワードを忘れた方へ</span>
      <span className='mt-5 text-[16px]'>メールアドレス</span>
      <Input
        placeholder='メールアドレス'
        onChange={(text: any) => {setEmail(text?.target?.value)}}
        className={`w-full mt-1 ${validationErrors.email ? 'border-rose-600' : ''}`}
      />
      <span className='text-[#DC3545] text-[14px] mt-1'>{ validationErrors.email }</span>
      <span className='mt-4 text-[16px]'>登録済みのメールアドレスへ認証コードを送信しました。</span>
      <Button onClick={() => {handleCodeRequest()}} className='bg-[#323539] text-white mt-8 text-[16px]'>
        認証コードを送信
      </Button>
      <hr className='w-full mt-6 bg-black' />
      <Button onClick={() => navigate('/login')} 
        className='bg-white text-[#323539] text-[16px] border-[#323539] mt-8 w-[70%] self-center'>
          ログインはこちら
      </Button>
    </>
  ) 
} 

export default EmailEntryForm
