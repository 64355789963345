import { Input } from 'antd'
const { TextArea } = Input

type Prop = {
  setDescription: (value: string) => void
}
export default function Description(prop: Prop) {
  const handleSaveDescription = (value: string) => {
		prop.setDescription(value)
	}

  return (
    <div className='w-[256px] mt-auto'>
      <div className='text-sm font-bold mb-2'>
        生成したいイメージ<label className='text-[#F35746] text-[10px] font-normal ml-2'>必須</label>
      </div>
      <TextArea
        rows={6}
        placeholder='例：ベロア素材を使ったトレンドライクなデザイン。カーキ色で生成。'
        maxLength={60000}
        className='w-[256px] bg-[#F6F6F6]'
        onChange={(e) => handleSaveDescription(e.target.value)}
      />
    </div>
  )
}
