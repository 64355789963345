import { Button, Input, Modal, Spin, message } from 'antd'
import Step1 from '../../../assets/step1.svg'
import Step2 from '../../../assets/step2.svg'
import { useState } from 'react'
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined'

type Props = {
  value: string
  onChange: any
  onSubmit: any
  open: boolean
  onClose: () => void
  generatedLora: any
}

const Steps = (props: any) => {
  const { step } = props
  const stepImg: any = {
    1: Step1,
    2: Step2
  }

  return (
    <div className='flex justify-center mt-5 mb-8'>
      <div className='w-[145px] h-[32px] flex justify-between'>
        <img src={stepImg[step]} />
      </div>
    </div>
  )
}

export default function TrainLoraSuccessModal(props: Props) {
  const { onClose, open, value, onChange, onSubmit, generatedLora } = props

  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const submit = async () => {
    try {
      setLoading(true)
      await onSubmit()
      setStep(2)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className='text-base font-medium mb-4 text-black'>ブランドプロフィールの設定</div>
            <div className='text-xs font-medium mb-4 text-black'>
              ブランドの特徴を教えてください。
              <br />
              ここでは「ブランドプロフィール」として、あなたのブランドのスタイルやコンセプト、顧客層など、ブランドの独自性を反映したキーワードを入力してください。
            </div>
            <div className='mb-4'>
              <Input
                className='h-[40px]'
                placeholder='例: エレガント、モダン、若者向け'
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
            </div>
          </>
        )
      case 2:
        return (
          <>
            <div className='text-base font-medium mb-4 text-center text-black'>
              ブランド学習ファイルを保存しますか？
            </div>
            <div className='text-xs font-medium mb-4 text-center text-black'>
              ローカルに保存する場合はダウンロードボタンをクリックして下さい。
            </div>
          </>
        )
      default:
        break
    }
  }
  const renderButtons = () => {
    switch (step) {
      case 1:
        return (
          <Button
            className='w-[198px] h-[50px] bg-[#323539] text-white mt-[25px] cursor-pointer'
            disabled={!value || loading}
            onClick={submit}
          >
            次へ
          </Button>
        )
      case 2:
        return (
          <div className='mt-[25px]'>
            <Button
              className='w-[198px] h-[50px] mr-3 cursor-pointer'
              onClick={() => (window.location.href = window.location.origin + window.location.pathname)}
            >
              閉じる
            </Button>
            <a href={generatedLora?.url} target='_blank'>
              <Button
                className='w-[198px] h-[50px] bg-[#323539] text-white cursor-pointer'
                onClick={() => message.success('ブランド学習ファイルをローカルに保存しました。')}
              >
                ダウンロード
              </Button>
            </a>
          </div>
        )
      default:
        break
    }
  }
  return (
    <Modal
      title={<div className='text-center'>ブランド学習完了</div>}
      open={open}
      onCancel={onClose}
      style={{ height: 409, padding: 0 }}
      width={600}
      closable={step > 1}
      maskClosable={step > 1}
      closeIcon={<CloseCircleOutlined className='text-black'/>}
      footer={[
        <div className='flex justify-center' key={'ok'}>
          {renderButtons()}
        </div>
      ]}
      centered
    >
      <Spin spinning={loading}>
        <div className='h-[235px] relative'>
          <Steps step={step} />
          {renderContent()}
          <div className='bg-[#D3D3D3] w-[600px] left-[-24px] absolute h-[1px] bottom-0'></div>
        </div>
      </Spin>
    </Modal>
  )
}
