import { Flex, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import CommonAPIs from '../../../controller/API/CommonAPIs'
import axios from 'axios'

const TemplateForm = ({ onFinishSetupTemplate, template = null }) => {
  const [templateName, setTemplateName] = useState('')
  const [image, setImage] = useState(null)
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [titleError, setTitleError] = useState(null)
  const [descriptionError, setDescriptionError] = useState(null)
  const [imageError, setImageError] = useState(null)
  const [isShowInfo, setIsShowInfo] = useState(false)

  useEffect(() => {
    if (template) {
      setTemplateName(template?.title)
      setDescription(template?.content)
      setImage(template?.thumbnail)
      setIsShowInfo(true)
    } else {
      setIsShowInfo(false)
    }
  }, [])

  const getImgURL = () => {
    if (template?.thumbnail) {
      return image
    } else {
      return URL.createObjectURL(image)
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      setImage(file)
    }
  }

  const validateTemplateData = () => {
    if (templateName == '' || templateName == null || templateName == undefined) {
      setTitleError('テンプレート名を入力してください')
      return false
    } else {
      setTitleError(null)
    }
    if (image == '' || image == null || image == undefined) {
      setImageError('画像をアップロードしてください')
      return false
    } else {
      setImageError(null)
    }
    if (description == '' || description == null || description == undefined) {
      setDescriptionError('参照文を入力してください')
      return false
    } else {
      setDescriptionError(null)
    }
    return true
  }

  const onClickCreateTemplate = () => {
    console.log('Template Name:', templateName)
    console.log('Image:', image)
    console.log('Description:', description)

    const validated = validateTemplateData()

    if (validated == false) {
      return
    }

    setIsLoading(true)
    CommonAPIs.createTemplate(templateName, description, image)
      .then((res) => {
        onFinishSetupTemplate()
      })
      .catch((error) => {
        console.log('Error creating template:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
      {isLoading && <Spin size='large' fullscreen />}
      <Flex style={{ flexDirection: 'column', width: '70%' }}>
        <h2>商品説明のテンプレートを作成します</h2>
        <Flex style={{ background: 'white', display: 'flex', flexDirection: 'column', padding: 20, borderRadius: 12 }}>
          <p className='mt-0'>
            自社の商品説明文のスタイルや特徴を反映したテンプレートを作成します。<br />
            参考となる商品画像と商品説明文をアップロードしてください。
          </p>
          <div className='form-group' style={{ width: '45' }}>
            <label>テンプレート名</label>
            <input
              type='text'
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              placeholder='テンプレート名を入力'
              style={{
                border: titleError ? '1px solid #F35746' : '1px solid #CCCCCC'
              }}
              disabled={isShowInfo}
            />
            {titleError && <div style={{ color: '#F35746', fontSize: 10, marginTop: 4 }}>{titleError}</div>}
          </div>
          <div className='form-group'>
            <label>参照画像</label>
            <div className='image-upload' style={{ height: 250, placeItems: 'self-start' }}>
              <input type='file' onChange={handleImageUpload} disabled={isShowInfo} />
              {!image && (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    justifyContent: 'center',
                    placeContent: 'center'
                  }}
                >
                  <div style={{ color: '#CCCCCC' }}>ここに画像をドロップ</div>
                  <div style={{ color: '#CCCCCC' }}>-または-</div>
                  <div style={{ color: '#CCCCCC' }}>クリックしてアップロード</div>
                </div>
              )}
              {image && (
                <img
                  src={getImgURL()}
                  alt='Uploaded'
                  style={{ height: '100%', objectFit: 'contain', placeSelf: 'flex-start' }}
                />
              )}
            </div>
            {imageError && <div style={{ color: '#F35746', fontSize: 10, marginTop: 4 }}>{imageError}</div>}
          </div>
          <div className='form-group'>
            <label>参照文</label>
            <textarea
              style={{
                height: 250
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder='参照文を入力'
              disabled={isShowInfo}
            ></textarea>
            {descriptionError && <div style={{ color: '#F35746', fontSize: 10, marginTop: 4 }}>{descriptionError}</div>}
          </div>
        </Flex>
        {!isShowInfo && (
          <button
            onClick={onClickCreateTemplate}
            style={{
              marginTop: 40,
              width: 250,
              height: 40,
              background: '#323539',
              placeSelf: 'center',
              borderRadius: 6
            }}
          >
            テンプレートを作成する
          </button>
        )}
      </Flex>
    </Flex>
  )
}

export default TemplateForm
