import { useState, useContext, useMemo } from 'react'
import { Col, Row, Flex, Button, Slider, SliderSingleProps } from 'antd'
import Progress from './Progress'
import CommonAPIs from '../../../controller/API/CommonAPIs'
import { AppContext } from '../../../hooks/AppContext'

type Props = {
  blendedImage: string
  originalImage: string
  category: string
  maskImg: string
  batchSize: string
  fabric: any
  onBack: () => void
  onNextStep: (images: string[]) => void
}

const ParameterAdjustment = (props: Props) => {
  const { blendedImage, originalImage, category, fabric, maskImg, batchSize, onNextStep, onBack } = props
  const { showErrorApiAlert } = useContext(AppContext)
  const [imgGenerationProgress, setImgGenerationProgress] = useState(false)
  const [denoisingStrength, setDenoisingStrength] = useState(0.45)
  const [jobId, setJobId] = useState<number>(0)

  const marks: SliderSingleProps['marks'] = useMemo(() => {
    return {
      '-1': '-1',
      1: '1'
    }
  }, [])

  const handleDenoisingStrength = (value: number) => {
    const stepValue = Number((Number((value - -1).toFixed(1)) / 20).toFixed(2))
    const realValue = Number((0.4 + stepValue).toFixed(2))

    setDenoisingStrength(realValue)
  }

  const handleImgGenerate = () => {
    const blendedImageFile = handleConvertBase64ToFile(blendedImage)
    const originalImageFile = handleConvertBase64ToFile(originalImage)
    const maskImgFile = handleConvertBase64ToFile(maskImg)
    const fabricImg = fabric.startsWith('data:image/') ? handleConvertBase64ToFile(fabric) : fabric

    CommonAPIs.generateFabricAiImg(
      blendedImageFile,
      originalImageFile,
      maskImgFile,
      denoisingStrength,
      category,
      batchSize,
      fabricImg
    )
      .then((res) => {
        if (res.code === 200) {
          setImgGenerationProgress(true)
          setJobId(res.data.id)
        } else {
          showErrorApiAlert(res.data.message)
        }
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const handleConvertBase64ToFile = (base64: string) => {
    const filename = 'image.jpg'
    const mimeType = 'image/jpeg'
    // Split the base64 string to get the data part
    const dataPart = base64.split(',')[1]

    // Convert the base64 data to a Blob
    const blob = atob(dataPart)
    const arrayBuffer = new ArrayBuffer(blob.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < blob.length; i++) {
      uint8Array[i] = blob.charCodeAt(i)
    }

    // Create a Blob
    const blobObject = new Blob([uint8Array], { type: mimeType })

    // Create a File
    const file = new File([blobObject], filename, { type: mimeType })

    return file
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        className='container m-5 flex rounded-lg'
        style={{
          margin: 30,
          overflow: 'hidden',
          background: 'white',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          paddingBottom: 30,
          paddingInline: 30,
          paddingTop: 30
        }}
      >
        {imgGenerationProgress && (
          <Progress jobId={jobId} setImgGenerationProgress={setImgGenerationProgress} onNextStep={onNextStep} />
        )}
        <Col span={12} className='flex justify-center'>
          <img src={blendedImage} alt='' className='flex self-center' />
        </Col>
        <Col span={12} className=''>
          <Flex className='flex-wrap px-[25%] pt-[25%]'>
            <span className='w-full mb-6'>StableDiffusionの設定値</span>
            <div className='w-full flex flex-col'>
              <span className='mb-2'>Denoising strength（立体を出す効果）</span>
              <Slider
                marks={marks}
                min={-1}
                max={1}
                step={0.2}
                defaultValue={0}
                onChange={(value) => handleDenoisingStrength(value)}
              />
              <Flex className='flex justify-between mt-80'>
                <Button
                  className='w-[198px] h-[50px] size-[16px]'
                  style={{ borderRadius: 9, borderColor: '#000000', marginRight: 20 }}
                  onClick={onBack}
                >
                  キャンセル
                </Button>
                <Button
                  type='primary'
                  className='w-[198px] h-[50px] size-[16px]'
                  style={{ background: '#323539', borderRadius: 9 }}
                  onClick={handleImgGenerate}
                >
                  画像生成
                </Button>
              </Flex>
            </div>
          </Flex>
        </Col>
      </div>
    </div>
  )
}

export default ParameterAdjustment
