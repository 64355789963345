import React, { useContext, useEffect, useState, useRef } from 'react'
import { Input, Button, Row, Col } from 'antd'
import { AppContext } from '../../../hooks/AppContext'
import AuthAPIs from '../../../controller/API/AuthAPIs'

type Props = {
  setStep: () => void
  email: string
}

const VerificationCodeEntryForm: React.FC<Props> = (prop: Props) => {
	const { setStep, email  } = prop
	const { setIsSpinning, showErrorApiAlert, showProcessingAlert } = useContext(AppContext)
	const [code, setCode] = useState(Array(6).fill(''));
	const inputRefs = useRef<any[]>([]);
	const [validationError, setValidationError] = useState('')
	
	useEffect(() => {
		inputRefs.current[0]?.focus();
	}, []);

	const handleChange = (e: any, index: any) => {
		if (isNaN(e.target.value) || e.target.value.length > 1) {
			return;
		}

		const newCode = [...code];
		newCode[index] = e.target.value;
		setCode(newCode);
		if (e.target.value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
	};
	
	const handleVerifyCode = () => {
		setValidationError('')
		setIsSpinning(true)

		const verificationCode = code.join('');

		if (!verificationCode) {
			setValidationError('認証コードを入力してください。')
			setIsSpinning(false)
			return
		}

		if (verificationCode.length !== 6) {
			setValidationError('認証コードの形式が間違っています。')
			setIsSpinning(false)
			return
		}

		AuthAPIs.sendApiVerifyCode(email, verificationCode)
      .then((res) => {
				setStep()
			})
      .catch((err) => {
				if (err.response.data.message == '認証コードが間違っています。') {
					setValidationError('認証コードが間違っています。')
					return
				}
        showErrorApiAlert(err)
      })
      .finally(() => setIsSpinning(false))
	}
	
	const handleCodeRequest = () => {
    setIsSpinning(true)

    AuthAPIs.sendEmailVerificationCodeRequest(email)
      .then((res) => {
				showProcessingAlert('認証コードを再送信しました。メールを確認してください。')
			})
      .catch((err) => {
        showErrorApiAlert(err)
      })
      .finally(() => setIsSpinning(false))
  }
	
	return (
		<>
			<span className='text-[32px] text-center'>認証コード</span>
			<p className='text-[14px] text-center'>
				{email}に認証コードを送信します。<br />
				コードを入力してパスワードを再設定してください
			</p>
			<span className='mt-3 text-[24px]'>認証コード</span>
			<Row justify="center" className='mt-3 w-full flex justify-between'>
				{code.map((value, index) => (
					<Col key={index} style={{ marginRight: '5px' }}>
						<Input
							type="text"
							placeholder='0'
							value={value}
							maxLength={1}
							onChange={(e) => handleChange(e, index)}
							style={{ width: '50px', height: '50px', textAlign: 'center', fontSize: '24px' }}
							className='font-bold'
							ref={(el) => (inputRefs.current[index] = el)}
						/>
					</Col>
				))}
			</Row>
			<span className='text-[#DC3545] text-[14px] mt-1'>{ validationError }</span>
			<span className='text-[16px] mt-5'>認証コードが届かない方へ</span>
			<span className='text-[16px] mt-3 underline hover:cursor-pointer' onClick={() => handleCodeRequest()}>認証コードを再送信する</span>
			<Button onClick={() => { handleVerifyCode() }} className='bg-[#323539] leading-[24px] text-white mt-8 text-[16px]'>
				認証コードを送信
			</Button>
		</>
	)
}

export default VerificationCodeEntryForm
