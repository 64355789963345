import { Layout } from 'antd'
import React, { useEffect, useState } from 'react'
import ProductForm from './components/ProductForm'
import TemplateForm from './components/TemplateForm'
import ProductContentForm from './components/ProductContentForm'
import { set } from 'lodash'
import { useThreadStore } from '../../controller/store'
import Constant from '../../controller/Constant'
import CommonAPIs from '../../controller/API/CommonAPIs'
import { useLocation } from 'react-router-dom'

const CreateProductContentScreen = () => {
  const [stepScreen, setStepScreen] = useState(null)
  const [productName, setProductName] = useState('')
  const [materialInfo, setMaterialInfo] = useState('')
  const [otherInfo, setOtherInfo] = useState('')
  const [sizeInfo, setSizeInfo] = useState('')
  const [image, setImage] = useState(null)
  const [productInfo, setProductInfo] = useState(null)
  const { setScreenKey } = useThreadStore()
  const location = useLocation()
  const { state } = location
  const [includePermissionPopup, setIncludePermissionPopup] = useState(false)

  useEffect(() => {
    setScreenKey(Constant.keyScreen.createProductContent)
  }, [])

  useEffect(() => {
    if (state?.step !== null && state?.step !== undefined && state?.step !== '') {
      setStepScreen(parseInt(state?.step))
    } else {
      CommonAPIs.getMyTemplates()
        .then((res) => {
          console.log(res)
          if (Object.values(res).length > 0) {
            setStepScreen(1)
          } else {
            setStepScreen(0)
          }
        })
        .catch((err) => {
          console.log(err)
          setStepScreen(0)
        })
    }
  }, [])

  const onFinishSetupProductInfo = (info, productImg) => {
    setStepScreen(2)
    console.log(info)
    setProductInfo(info)
    setImage(productImg)
  }

  const onFinishSetupTemplate = () => {
    setStepScreen(1)
  }

  const getContent = () => {
    switch (stepScreen) {
      case 1:
        return (
          <ProductForm
            onFinishSetupProductInfo={onFinishSetupProductInfo}
            showCreateTemplateScreen={() => {
              setStepScreen(0)
            }}
            info={productInfo}
            productImg={image}
            includePermissionPopup={includePermissionPopup}
          />
        )
      case 2:
        return (
          <ProductContentForm
            info={productInfo}
            showCreateTemplateScreen={() => {
              setStepScreen(0)
              setProductInfo(null)
            }}
            showSetupProductInfoScreen={() => {
              setStepScreen(1)
            }}
            setIncludePermissionPopup={setIncludePermissionPopup}
          />
        )
      case 0:
        return <TemplateForm onFinishSetupTemplate={onFinishSetupTemplate} template={state?.template} />
      default:
        return <div />
    }
  }

  return <Layout style={{ paddingBottom: 40 }}>{getContent()}</Layout>
}

export default CreateProductContentScreen
