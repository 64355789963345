import { CloseOutlined, PlusSquareOutlined } from '@ant-design/icons'
import useImg2Img from '../../../hooks/useImg2Img'
import { useEffect, useState } from 'react'
import CheckpointItem from '../../../components/Checkpoint/CheckpointItem'

type Props = {
  setCheckpoint: (checkpoint: any) => void
  disabled: boolean
}

export default function SelectCheckpoint(prop: Props) {
  const [openCheckPointModal, setOpenCheckPointModal] = useState(false)
  const { getNewCheckpoint, checkPoints, checkPointSelected, handleSelectCheckpoint } = useImg2Img()

  useEffect(() => {
    prop.setCheckpoint(checkPointSelected)
  }, [checkPointSelected])

  useEffect(() => {
    getNewCheckpoint()
  }, [])

  const selectCheckpoint = (checkpoint: any) => {
    if (prop.disabled) return
    setOpenCheckPointModal(false)
    handleSelectCheckpoint(checkpoint)
  }
  return (
    <>
      <div className='text-sm font-bold mb-2'>
        生成モデルを選択 <label className='text-[#F35746] text-[10px] font-normal ml-2'>必須</label>
      </div>
      {checkPointSelected && checkPointSelected.name ? (
        <CheckpointItem
          data={checkPointSelected}
          onOpenReselect={() => {
            if (prop.disabled) return
            setOpenCheckPointModal(true)
          }}
          style={{ width: 256 }}
        />
      ) : (
        <div className='w-[256px] h-[42px] bg-[#F6F6F6] rounded-lg flex justify-between items-center mb-5'>
          <label className='ml-5 text-xs font-medium'>モデルを設定</label>
          <PlusSquareOutlined
            className='text-lg mr-2 cursor-pointer'
            onClick={() => {
              if (prop.disabled) return
              setOpenCheckPointModal(true)
            }}
          />
        </div>
      )}

      <div
        className='bg-white fixed left-[325px] w-[354px] h-[80%] top-[10%] overflow-y-auto overflow-x-hidden p-5'
        style={{
          display: openCheckPointModal ? '' : 'none'
        }}
      >
        <div className='flex mb-2 justify-between w-[105%]'>
          <label className='text-sm font-bold'>生成モデルを選択</label>
          <CloseOutlined className='text-lg cursor-pointer' onClick={() => setOpenCheckPointModal(false)} />
        </div>
        {checkPoints.map((x) => (
          <CheckpointItem key={x.name} data={x} onSelect={selectCheckpoint} style={{ width: 314 }} />
        ))}
      </div>
    </>
  )
}
