import { Flex, Progress } from 'antd'
import { useEffect, useState } from 'react'
import CommonAPIs from '../../../controller/API/CommonAPIs'

type Props = {
  onNextStep: (images: string[]) => void
  jobId: number
  setImgGenerationProgress: (hidden: boolean) => void
}

const ProgressComponent = (props: Props) => {
  const { onNextStep, jobId, setImgGenerationProgress } = props
  const [progressPercent, setProgressPercent] = useState(0)
  const [progressStatus, setProgressStatus] = useState(1)
  const [stopLoop, setStopLoop] = useState<boolean>(false)
  const [timerId, setTimeId] = useState<any>()

  useEffect(() => {
    const apiTimerId = setInterval(() => {
      CommonAPIs.checkStatusMediaJob(jobId).then((res) => {
        if (res.status === 3) {
          onNextStep(res.medias.map((item: any) => item.url))
        }
        if (res.status === 2) {
          clearInterval(apiTimerId)
          setStopLoop(true)
        }

        setProgressStatus(res.status)
      })
    }, 5000)

    return () => clearInterval(apiTimerId)
  }, [])

  useEffect(() => {
    if (!stopLoop) {
      setTimeId(
        setInterval(() => {
          setProgressPercent((prevState) => {
            if (progressStatus === 1 && prevState < 90) {
              return ++prevState
            } else {
              return prevState
            }
          })
        }, 1000)
      )
    } else {
      clearInterval(timerId)
    }
  }, [stopLoop])

  const exitModal = (event: any) => {
    const targetElement = document.getElementById('modal')
    if (event.target === targetElement && progressStatus === 2) {
      setImgGenerationProgress(false)
    }
  }

  return (
    <Flex
      gap='small'
      id='modal'
      vertical
      className='z-10 w-[85%] h-[90vh] fixed top-1/2 transform -translate-y-1/2'
      align='center'
      justify='center'
      onClick={(event) => exitModal(event)}
    >
      <Flex align='center' justify='center' className='absolute flex-wrap w-80'>
        <Progress
          percent={progressPercent}
          status={progressStatus === 2 ? 'exception' : 'active'}
          className='w-80 z-30 self-center px-14 '
          showInfo={false}
        />
        <br />
        {progressStatus === 2 ? (
          <span className='font-bold text-red-600'>
            画像生成が失敗しました。
            <br />
            再度生成してください。
          </span>
        ) : (
          <div className='flex flex-col justify-center content-center'>
            <span className='font-bold self-center'>画像生成中</span>
            <span className='p-2 text-center text-xs self-center'>※別ページに移動しても生成後に</span>
            <span className='pt-0 p-2 text-center text-xs self-center'>
              画像生成履歴からご確認いただけます。
            </span>
          </div>
        )}
      </Flex>
      <div className='w-[410px] h-[250px] flex justify-center content-center z-20 bg-slate-500 opacity-10 blur-sm rounded-2xl'></div>
    </Flex>
  )
}

export default ProgressComponent
