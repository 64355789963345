export const fillTransparentWithBlackAndWhite = (base64Image: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = base64Image

    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        reject(new Error('Failed to get canvas context'))
        return
      }

      canvas.width = img.width
      canvas.height = img.height

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0)

      // Get the image data from the canvas
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const data = imageData.data

      // Loop through each pixel
      for (let i = 0; i < data.length; i += 4) {
        const alpha = data[i + 3]
        if (alpha === 0) {
          // If the pixel is transparent, set it to black
          data[i] = 0 // Red channel
          data[i + 1] = 0 // Green channel
          data[i + 2] = 0 // Blue channel
          data[i + 3] = 255 // Set alpha to 255 (opaque)
        } else {
          // If the pixel is not transparent, set it to white
          data[i] = 255 // Red channel
          data[i + 1] = 255 // Green channel
          data[i + 2] = 255 // Blue channel
          data[i + 3] = 255 // Set alpha to 255 (opaque)
        }
      }

      // Put the modified image data back on the canvas
      ctx.putImageData(imageData, 0, 0)

      // Get the new base64 image
      const newBase64Image = canvas.toDataURL()
      resolve(newBase64Image)
    }

    img.onerror = (error) => {
      reject(new Error('Image failed to load: ' + error))
    }
  })
}

export const getImageBase64FromUrl = async (url: string) => {
  if (!url.includes('http')) {
    return url
  }
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader()
      reader.onloadend = function () {
        resolve(reader.result.split(',')[1])
      }
      reader.onerror = function (error: any) {
        reject(error)
      }
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    console.error('Error converting image to base64:', error)
    return url
  }
}
export const removeBackgroundWithMask = (originalBase64Image: string, maskBase64Image: string): Promise<string> => {
  const originalCanvas = document.createElement('canvas')
  const originalCtx: any = originalCanvas.getContext('2d')
  const maskCanvas = document.createElement('canvas')
  const maskCtx: any = maskCanvas.getContext('2d')

  const originalImage = new Image()
  const maskImage = new Image()

  return new Promise((resolve) => {
    let imagesLoaded = 0

    const onImageLoad = () => {
      imagesLoaded += 1
      if (imagesLoaded < 2) return

      originalCanvas.width = originalImage.width
      originalCanvas.height = originalImage.height
      maskCanvas.width = maskImage.width
      maskCanvas.height = maskImage.height

      originalCtx.drawImage(originalImage, 0, 0)
      maskCtx.drawImage(maskImage, 0, 0)

      const originalImageData = originalCtx.getImageData(0, 0, originalCanvas.width, originalCanvas.height)
      const maskImageData = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height)
      const originalData = originalImageData.data
      const maskData = maskImageData.data

      for (let i = 0; i < originalData.length; i += 4) {
        if (maskData[i] === 255 && maskData[i + 1] === 255 && maskData[i + 2] === 255) {
          originalData[i + 3] = 0
        }
      }

      originalCtx.putImageData(originalImageData, 0, 0)
      const newBase64Image = originalCanvas.toDataURL()
      resolve(newBase64Image)
    }

    originalImage.onload = onImageLoad
    maskImage.onload = onImageLoad

    originalImage.src = originalBase64Image
    maskImage.src = maskBase64Image
  })
}

export const resizeImage = (imageURL: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = imageURL

    img.onload = () => {
      let newWidth = img.width
      let newHeight = img.height

      if (newHeight > 768 || newWidth > 576) {
        if (newHeight > 768) {
          newHeight = 768
          newWidth = (768 * img.width) / img.height
        }
        if (newWidth > 576) {
          newWidth = 576
          newHeight = (576 * img.height) / img.width
        }
      }

      resolve(resizeCanvas(img, newWidth, newHeight))
    }

    img.onerror = reject
  })
}
const resizeCanvas = (image: any, width: any, height: any) => {
  const canvas = document.createElement('canvas')
  const ctx: any = canvas.getContext('2d')

  canvas.width = width
  canvas.height = height

  ctx.drawImage(image, 0, 0, width, height)

  return canvas.toDataURL()
}
export const convertMaskToCanvas = (imageUrl: any, canvas: any) => {
  return new Promise((resolve: any, reject: any) => {
    const ctx = canvas.getContext('2d')
    const img = new Image()

    img.crossOrigin = 'Anonymous' // Necessary for cross-origin images
    img.src = imageUrl

    img.onload = () => {
      // Set canvas dimensions
      canvas.width = img.width
      canvas.height = img.height

      // Draw image onto the canvas
      ctx.drawImage(img, 0, 0)

      // Get image data
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const data = imageData.data

      // Loop through all pixels
      for (let i = 0; i < data.length; i += 4) {
        const red = data[i]
        const green = data[i + 1]
        const blue = data[i + 2]
        const alpha = data[i + 3]

        // Check if the pixel is black
        if (red === 0 && green === 0 && blue === 0 && alpha !== 0) {
          // Make the pixel transparent
          data[i + 3] = 0 // Set alpha to 0 (fully transparent)
        }
        // Check if the pixel is white
        else if (red === 255 && green === 255 && blue === 255) {
          // Change white pixel to red
          data[i] = 0 // Red
          data[i + 1] = 0 // Green
          data[i + 2] = 255 // Blue
          data[i + 3] = 128 // Alpha (fully opaque)
        }
      }

      // Put modified image data back to canvas
      ctx.putImageData(imageData, 0, 0)

      resolve()
    }

    img.onerror = (err) => {
      reject(err)
    }
  })
}
export const overlayImages = async (base64Img1: string, base64Img2: string): Promise<string> => {
  if (!base64Img2) return base64Img1
  const loadImage = (base64: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = base64
    })
  }

  const img1 = await loadImage(base64Img1)
  const img2 = await loadImage(base64Img2)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('Unable to get canvas context')
  }

  canvas.width = img1.width
  canvas.height = img1.height
  ctx.drawImage(img1, 0, 0)
  const imgData1 = ctx.getImageData(0, 0, canvas.width, canvas.height)

  ctx.drawImage(img2, 0, 0)
  const imgData2 = ctx.getImageData(0, 0, canvas.width, canvas.height)
  const combinedImageData = ctx.createImageData(canvas.width, canvas.height)

  for (let i = 0; i < imgData1.data.length; i += 4) {
    const r1 = imgData1.data[i]
    const g1 = imgData1.data[i + 1]
    const b1 = imgData1.data[i + 2]
    const a1 = imgData1.data[i + 3]

    const r2 = imgData2.data[i]
    const g2 = imgData2.data[i + 1]
    const b2 = imgData2.data[i + 2]
    const a2 = imgData2.data[i + 3]

    if (r1 === 255 && g1 === 255 && b1 === 255) {
      combinedImageData.data[i] = r1
      combinedImageData.data[i + 1] = g1
      combinedImageData.data[i + 2] = b1
      combinedImageData.data[i + 3] = a1
    } else if (r2 === 255 && g2 === 255 && b2 === 255) {
      combinedImageData.data[i] = r2
      combinedImageData.data[i + 1] = g2
      combinedImageData.data[i + 2] = b2
      combinedImageData.data[i + 3] = a2
    } else {
      combinedImageData.data[i] = r1
      combinedImageData.data[i + 1] = g1
      combinedImageData.data[i + 2] = b1
      combinedImageData.data[i + 3] = a1
    }
  }

  ctx.putImageData(combinedImageData, 0, 0)

  return canvas.toDataURL('image/png')
}
