import { UploadOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import React, { useEffect, useState } from 'react'
import { ImageType } from '../../../types'
import { Upload, UploadFile } from 'antd/lib'
import { UploadChangeParam } from 'antd/es/upload'
import { RcFile } from 'antd/lib/upload'

type Props = {
  isDisabled?: boolean
  handleUploadChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined
  beforeUpload: ((file: RcFile, FileList: RcFile[]) => void) | undefined
  fileBase64: ImageType
}

const UploadImg2Img: React.FC<Props> = (props) => {
  const { isDisabled = false, handleUploadChange, fileBase64, beforeUpload } = props
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [heightUpload, setHeightUpload] = useState<number>(434)
  const [widthUpload, setWidthUpload] = useState<number>(434)

  const uploadButton = (
    <Flex vertical justify='center' align='center'>
      <UploadOutlined className='text-[30px] text-[#6B7280]' />
      <p className='flex text-[#6B7280] mt-2 whitespace-pre-line text-center'>
        {`ここに画像をドロップ\n-または-\nクリックしてアップロード`}
      </p>
    </Flex>
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const isHasFileUpload = (): boolean => {
    if (fileBase64) {
      return true
    }
    return false
  }

  useEffect(() => {
    setHeightUpload(((isHasFileUpload() ? 176 : 246) * windowWidth) / 1440)
    setWidthUpload(((isHasFileUpload() ? 296 : 460) * windowWidth) / 1440)
  }, [windowWidth])

  return (
    <Flex className='mt-28 mb-28 h-full' align='center' justify='center' vertical>
      <Upload
        listType='picture'
        onChange={handleUploadChange}
        name='avatar'
        className='avatar-uploader'
        showUploadList={false}
        disabled={isDisabled}
        beforeUpload={beforeUpload}
      >
        <Flex
          className='p-3 cursor-pointer border-dashed border-[#6B7280] h-full rounded-[10px] border-[0.5px]'
          vertical
          style={{
            width: 434,
            aspectRatio: 1,
            // minHeight: 434,
            // minWidth: 434,
            // height: heightUpload,
            // width: widthUpload,
            backgroundColor: isDisabled ? '#f6f6f6' : 'white'
          }}
          justify='center'
          align='center'
        >
          <Flex justify='center' align='center'>
            {uploadButton}
          </Flex>
        </Flex>
      </Upload>
      <Flex className='relative gap-[40px] mt-10'>
        <div>
          <img height={300} src={require('../../../assets/fabric_home_before.png')} alt='' />
        </div>
        <div className='absolute left-1/2 top-[55%] transform -translate-x-1/2'>
          <img height={110} src={require('../../../assets/fabric_home_material.png')} alt='' />
        </div>
        <div className=''>
          <img height={300} src={require('../../../assets/fabric_home_after.png')} alt='' />
        </div>
      </Flex>
    </Flex>
  )
}

export default UploadImg2Img
