import { Col, Flex, Layout, Pagination, Row, Typography } from 'antd'
import { useContext, useEffect } from 'react'
import ImageItem from './components/ImageItem'
import MediaFilter from './components/MediaFilter'
import { AppContext } from '../../hooks/AppContext'
import useRenderHistory from '../../hooks/useRenderHistory'
import Constant from '../../controller/Constant'
import { RenderHistoryItemType } from '../../types/media_type'
import PointsView from './components/PointsView'
import HistoryType from './components/HistoryType'
import AIImagesHeader from './components/AIImagesHeader'
import LoraHeader from './components/LoraHeader'
import LoraItem from './components/LoraItem'
import DescriptionHeader from './components/DescriptionHeader'
import { get } from 'lodash'
import DescriptionItem from './components/DescriptionItem'

const RenderHistoryScreen = () => {
  const { setMenuSelected, isMobile } = useContext(AppContext)
  const {
    getRenderHistoryList,
    getLoraProcessing,
    handleFilter,
    schedule,
    setSchedule,
    loraProcessingList,
    isSpinning,
    loraList,
    totalPages,
    onChangePage,
    currentPage,
    setLoraProcessingList,
    isLoraHistory,
    setIsLoraHistory,
    imgList,
    getLorasTrained,
    renderImgsList,
    setRenderImgsList,
    getImgProcessing,
    tab,
    setTab,
    descriptionList,
    renderDescriptionList,
    getDescription,
    getRenderDescription,
    setCurrentPage
  } = useRenderHistory()

  useEffect(() => {
    setCurrentPage(0)
    switch (tab) {
      case 1:
        getRenderHistoryList()
        break
      case 3:
        getDescription()
        break
      default:
        getLorasTrained()
        break
    }
  }, [tab])

  useEffect(() => {
    setMenuSelected(Constant.keyScreen.renderHistory)
  }, [])

  useEffect(() => {
    if (currentPage <= 1) {
      if (tab === 2) {
        getLoraProcessing()
      } else if (tab === 1) {
        getImgProcessing()
      }
    } else {
      if (tab === 2) {
        setLoraProcessingList([])
      } else if (tab === 1) {
        setRenderImgsList([])
      } else {
      }
    }
  }, [currentPage])

  useEffect(() => {
    if (tab === 2) {
      setLoraProcessingList([])
      getLorasTrained()
    } else if (tab === 1) {
      setRenderImgsList([])
      getRenderHistoryList()
    } else {
      getDescription()
    }
  }, [currentPage])

  useEffect(() => {
    if (tab === 2) {
      const timerId = setInterval(() => {
        getLoraProcessing()
      }, 5000)

      if (loraProcessingList?.length <= 0) {
        clearInterval(timerId)
      }

      return () => {
        clearInterval(timerId)
      }
    }
  }, [loraProcessingList])

  useEffect(() => {
    if (tab === 1) {
      const timerId = setInterval(() => {
        getImgProcessing()
      }, 5000)

      if (renderImgsList?.length <= 0) {
        clearInterval(timerId)
      }

      return () => {
        clearInterval(timerId)
      }
    }
  }, [renderImgsList])

  return (
    <Layout className='bg-white p-5 h-full'>
      <MediaFilter schedule={schedule} setSchedule={setSchedule} handleFilter={handleFilter} />
      <PointsView />
      <HistoryType setTab={setTab} />
      <Col>
        {tab === 2 && (
          <>
            <LoraHeader />
            {loraProcessingList?.map((item: RenderHistoryItemType) => (
              <LoraItem isLoraProcessing={true} key={item?.id} item={item} />
            ))}
            {loraList?.map((item: RenderHistoryItemType) => (
              <LoraItem key={item?.id} item={item} />
            ))}
          </>
        )}
        {tab === 1 && (
          <>
            <AIImagesHeader />
            {renderImgsList?.map((item: RenderHistoryItemType) => (
              <ImageItem isLoraProcessing={true} key={item?.id} item={item} />
            ))}
            {imgList?.map((item: RenderHistoryItemType) => (
              <ImageItem key={item?.id} item={item} />
            ))}
          </>
        )}
        {tab === 3 && (
          <>
            <DescriptionHeader />
            {descriptionList?.map((item: RenderHistoryItemType) => (
              <DescriptionItem key={item?.id} item={item} />
            ))}
          </>
        )}
        {!isSpinning && imgList?.length <= 0 && renderImgsList?.length <= 0 && (tab === 1 || tab === 2) && (
          <Flex vertical justify='center' align='center' className='mt-[70px]'>
            <img src={require('../../assets/render-empty.png')} width='auto' height={80} />
            <div className='text-center mt-5 text-[16px]'>まだ画像がありません。</div>
          </Flex>
        )}
        {!isSpinning && descriptionList?.length <= 0 && tab === 3 && (
          <Flex vertical justify='center' align='center' className='mt-[70px]'>
            <img src={require('../../assets/description_empty.png')} width='auto' height={80} alt='' />
            <div className='text-center mt-5 text-[16px]'>まだ説明文がありません。</div>
          </Flex>
        )}
        <Flex justify='center' align='flex-end' className='mt-7'>
          {Number(totalPages) > 1 && (
            <Pagination
              current={currentPage}
              onChange={onChangePage}
              total={Number(totalPages) * 10}
              showSizeChanger={false}
            />
          )}
        </Flex>
      </Col>
    </Layout>
  )
}

export default RenderHistoryScreen
