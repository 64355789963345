import React, { useEffect, useState } from 'react'
import domtoimage from 'dom-to-image-more'
import { Slider, Button } from 'antd'
import axios from 'axios'

type Props = {
  onBack: () => void
  transparentImage: string
  fabricImg: string
  onNextStep: (img: string) => void
}

export default function ImageBlend(props: Props) {
  const { onBack, transparentImage, onNextStep } = props

  const [bgSize, setBgSize] = useState('90%')
  const [bgPositonX, setBgPositonX] = useState(50)
  const [bgPositonY, setBgPositonY] = useState(50)
  const [imageW, setImgW] = useState(0)
  const [imageH, setImgH] = useState(0)
  const [fabricBase64, setFabricBase64] = useState<string>('')

  const onChangeBgMode = (value: any) => {
    if (value == 1) {
      setBgSize('25%')
    } else if (value == 2) {
      setBgSize('60%')
    } else if (value == 3) {
      setBgSize('90%')
    } else if (value == 4) {
      setBgSize('150%')
    } else {
      setBgSize('200%')
    }
  }

  useEffect(() => {
    const fakeImage = new Image()
    fakeImage.src = transparentImage
    fakeImage.onload = () => {
      setImgW(fakeImage.width)
      setImgH(fakeImage.height)
    }
  }, [transparentImage])

  const download = async () => {
    const selector = document.getElementById('blend')
    const dataUrl = await domtoimage.toPng(selector)
    onNextStep(dataUrl)
  }

  useEffect(() => {
    if (props.fabricImg.startsWith('data:image/')) {
      setFabricBase64(props.fabricImg)
    } else {
      let dt = new Date().getTime()
      let img_url = `${props.fabricImg}?${dt}`

      axios
        .get(img_url, { responseType: 'arraybuffer' })
        .then((response) => {
          let image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
          let base64Image = `data:image/jpeg;base64,${image}`
          setFabricBase64(base64Image)
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }, [])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        className='container m-5 flex rounded-lg'
        style={{
          margin: 30,
          overflow: 'hidden',
          background: 'white',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          paddingBottom: 30,
          paddingInline: 30,
          paddingTop: 30,
          flexWrap: 'wrap'
        }}
      >
        <div className='relative shadow' id='blend' style={{ width: imageW, height: imageH }}>
          <img src={transparentImage} className='z-0' style={{ width: imageW, height: imageH }} />
          <img
            src={transparentImage}
            className='absolute top-0 left-0 w-full z-20'
            style={{ width: imageW, height: imageH }}
          />
          <div
            className='absolute top-0 left-0 z-10 mix-blend-multiply'
            style={{
              backgroundImage: `url(${fabricBase64})`,
              backgroundSize: bgSize,
              backgroundPositionX: bgPositonX + '%',
              backgroundPositionY: bgPositonY + '%',
              width: imageW,
              height: imageH
            }}
          ></div>
        </div>
        <div className='flex ml-[100px] flex-col w-[500px] mt-[10px]'>
          <div>調整ツール</div>
          <div className='mt-[40px]'>
            <p>スケール調整</p>
            <Slider
              styles={{
                track: {
                  background: '#5945ED'
                }
              }}
              defaultValue={3}
              min={1}
              max={5}
              onChange={onChangeBgMode}
            />

            <p className='mt-10'>水平移動</p>

            <Slider
              styles={{
                track: {
                  background: '#5945ED'
                }
              }}
              defaultValue={50}
              min={0}
              max={100}
              onChange={setBgPositonX}
            />
            <p className='mt-10'>垂直移動</p>

            <Slider
              styles={{
                track: {
                  background: '#5945ED'
                }
              }}
              defaultValue={50}
              min={0}
              max={100}
              onChange={setBgPositonY}
            />
          </div>
          <div className='mt-auto'>
            <div className='flex justify-between'>
              <Button
                className='w-[198px] h-[50px] size-[16px]'
                style={{ borderRadius: 9, borderColor: '#000000' }}
                onClick={onBack}
              >
                キャンセル
              </Button>
              <Button
                className='w-[198px] h-[50px] size-[16px]'
                type='primary'
                style={{ background: '#323539', borderRadius: 9 }}
                onClick={download}
              >
                次へ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
