import { Alert, Button, Col, Row, Typography } from 'antd'
import React, { useEffect } from 'react'
import CommonAPIs from '../../../controller/API/CommonAPIs'
import { formatNumber } from '../../../utils/utils'

type Props = {}

const PointsView = (props: Props) => {
  const [allTime, setAllTime] = React.useState(0)
  const [usedTime, setUsedTime] = React.useState(0)

  const checkConditionTime = () => {
    if (usedTime >= allTime * 0.9) {
      return true
    }
    return false
  }

  const showConfirmConfirmRecharge = () => {
    const userConfirmed = window.confirm('管理者にチケット追加のリクエストをする通知を送信しますか?')
    if (userConfirmed) {
      CommonAPIs.sendConfirmRechargeToSlack().then((res) => {
        console.log('==== Sent confirm recharge to slack ====')
      })
    } else {
      // Thực hiện hành động khi người dùng nhấn "Cancel"
    }
  }

  const getMySecondsTime = () => {
    CommonAPIs.getMySecondsTime().then((res) => {
      console.log(res)
      setAllTime(res?.all_time)
      setUsedTime(res?.time_used)
    })
  }

  useEffect(() => {
    getMySecondsTime()
  }, [])

  return (
    <Row
      style={{
        width: 336,
        height: 59,
        border: '1px solid #C2C2C2',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 12
      }}
    >
      <Col>
        <Typography
          style={{
            fontWeight: 'bold'
          }}
        >
          分消費
        </Typography>
        <Typography
          style={{
            fontWeight: 'bold'
          }}
        >
          {formatNumber(Number(usedTime / 60).toFixed(0))}/{formatNumber(Number(allTime / 60).toFixed(0))}分
        </Typography>
      </Col>
      <Button
        onClick={() => {
          // window.alert('追加時間の購入を確認するリクエストが正常に送信されました。')
          showConfirmConfirmRecharge()
        }}
        disabled={!checkConditionTime()}
        style={{
          height: 40,
          borderRadius: 40,
          width: 186,
          background: checkConditionTime() ? '#6AAFE6' : '#C6C6C6',
          color: 'white',
          marginLeft: 20
        }}
      >
        時間を追加
      </Button>
      {/* <div
        style={{
          height: 40,
          borderRadius: 40,
          width: 186,
          background: '#6AAFE6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography
          style={{
            color: 'white'
          }}
        >
          チケット追加
        </Typography>
      </div> */}
    </Row>
  )
}

export default PointsView
